import React, { useState } from "react";
import isRandomColor from "../utils/IsRandomColor";

const CAvatar = ({ src = null, name = "", alt, size, ...props }) => {
  const [imageError, setImageError] = useState(false);
  const handleImageError = () => {
    setImageError(true);
  };
  const getInitials = (name) => {
    return name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  return (
    <div
      {...props}
      style={{
        width: size,
        height: size,
        borderRadius: "50%",
        backgroundColor: isRandomColor(),
        color: "#fff",
        fontSize: size / 2.5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      {!imageError && src ? (
        <img
          src={src}
          alt={alt || "Profile"}
          onError={handleImageError}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      ) : (
        <span>{getInitials(name)}</span>
      )}
    </div>
  );
};

export default CAvatar;
