import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";

const SectionChartDivSummary = ({ series, data }) => {
  const [payoutRatioTtm, setPayoutRatioTtm] = useState(0);
  const [retainedEarnings, setRetainedEarnings] = useState(0);
  const [options, setOptions] = useState({
    chart: {
      type: "pie",
      height: 350,
      width: 250,
      backgroundColor: "transparent",
    },
    title: {
      text: ``,
    },
    tooltip: {
      pointFormat: "<b>{point.percentage:.1f}%</b>",
    },
    plotOptions: {
      pie: {
        innerSize: "70%",
        dataLabels: {
          enabled: false,
          format: "{point.name}: {point.percentage:.1f}%",
        },
        center: ["50%", "50%"],
      },
    },
    series: [],
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
  });

  useEffect(() => {
    setPayoutRatioTtm(data?.payoutRatioTtm);
    setRetainedEarnings(100 - data?.payoutRatioTtm);
  }, [data]);

  useEffect(() => {
    setOptions({
      ...optionss,
      series: series,
    });
    // eslint-disable-next-line
  }, [series]);

  // Konfigurasi Highcharts
  const optionss = {
    chart: {
      type: "pie",
      height: 200,
      width: 200,
    },
    title: {
      text: ``,
    },
    tooltip: {
      pointFormat: "<b>{point.percentage:.1f}%</b>",
    },
    plotOptions: {
      pie: {
        innerSize: "70%",
        dataLabels: {
          enabled: false,
          format: "{point.name}: {point.percentage:.1f}%",
        },
        center: ["50%", "50%"],
      },
    },
    series: [
      {
        name: "Ratio",
        colorByPoint: true,
        data: [
          {
            name: "Payout Ratio",
            y: payoutRatioTtm,
            color: "#23B19B",
          },
          {
            name: "Earnings Retained",
            y: retainedEarnings,
            color: "#E0E3EB",
          },
        ],
      },
    ],
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
  };

  const centerTextStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#23B19B",
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
      {data && (
        <div style={centerTextStyle}>
          <div>{`${payoutRatioTtm?.toFixed(1)}%`}</div>
        </div>
      )}
    </div>
  );
};

export default SectionChartDivSummary;
