import { useLazyQuery, useQuery } from "@apollo/client";
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { HiOutlinePresentationChartLine } from "react-icons/hi";
import { IoCloseSharp } from "react-icons/io5";
import { useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  Row,
} from "reactstrap";
import CLoading from "../../components/CLoading";
import CModalSide from "../../components/CModalSide";
import MetaHelmet from "../../components/MetaHelmet";
import { GlobalContext } from "../../contexts/GlobalContext";
import QuarterResult from "../../graphQL/Query/QuarterResult";
import QueryDetail from "../../graphQL/Query/QueryDetail";
import QuerySummary from "../../graphQL/Query/QuerySummary";
import { isCurrency } from "../../utils/isCurrency";
import isRandomColor from "../../utils/IsRandomColor";
import fieldAnnualIncome from "./fieldTable/fieldAnnualIncome.json";
import fieldBalanceSheets from "./fieldTable/fieldBalanceSheet.json";
import fieldCashFlows from "./fieldTable/fieldCashFlow.json";
import fieldCategoryChart from "./fieldTable/fieldCategoryChart.json";
import fieldPeer from "./fieldTable/fieldPeerComparison.json";
import fieldQuarterly from "./fieldTable/fieldQuarterly.json";
import fieldRatios from "./fieldTable/fieldRatio.json";
import SectionAnalys from "./sectionComponent/SectionAnalys";
import SectionBalance from "./sectionComponent/SectionBalance";
import SectionCashFlow from "./sectionComponent/SectionCashFlow";
import SectionChart from "./sectionComponent/SectionChart";
import SectionChartCapitalStruktur from "./sectionComponent/SectionChartCapitalStruktur";
import SectionChartCompare from "./sectionComponent/SectionChartCompare";
import SectionChartDivHistory from "./sectionComponent/SectionChartDivHistory";
import SectionChartDivSummary from "./sectionComponent/SectionChartDivSummary";
import SectionChartTrading from "./sectionComponent/SectionChartTrading";
import SectionDocument from "./sectionComponent/SectionDocument";
import SectionPeer from "./sectionComponent/SectionPeer";
import SectionProfitLoss from "./sectionComponent/SectionProfitLoss";
import SectionQuarter from "./sectionComponent/SectionQuarter";
import SectionRatio from "./sectionComponent/SectionRatio";
import SectionShareholding from "./sectionComponent/SectionShareholding";
import SectionSummary from "./sectionComponent/SectionSummary";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineController,
  BarController,
  TimeScale,
  ChartDataLabels,
  zoomPlugin,
  Filler,
  ArcElement
);

const Company = () => {
  const { companyId } = useParams();
  const { state, updateCompanyName } = useContext(GlobalContext);

  const [companiesDetail, setCompaniesDetail] = useState([]);
  const [ratio, setRatio] = useState({});
  const { t, i18n } = useTranslation("global");
  const [isExpandedAbout, setIsExpandedAbout] = useState(false);
  const [isOpenMore, setIsOpenMore] = useState(false);
  const [isOpenCompre, setIsOpenCompre] = useState(false);
  const [isCompareActive, setIsCompareActive] = useState(false);
  const [isTradingView, setIsTradingView] = useState(false);

  //for compound
  const [compoundSalesGrowth, setCompoundSalesGrowth] = useState(null);
  const [dataSetSalesGrowth, setDataSetSalesGrowth] = useState({
    labels: [],
    datasets: [],
  });
  const [optionsSalesGrowth, setOptionsSalesGrowth] = useState({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {},
    scales: {
      y: {},
    },
  });
  const [compoundProfitGrowth, setCompoundProfitGrowth] = useState(null);
  const [dataSetProfitGrowth, setDataSetProfitGrowth] = useState({
    labels: [],
    datasets: [],
  });
  const [optionsProfitGrowth, setOptionsProfitGrowth] = useState({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {},
    scales: {
      y: {},
    },
  });

  const [compoundstockPriceCagr, setCompoundStockPriceCagr] = useState(null);
  const [dataSetStockPriceCagr, setDataSetStockPriceCagr] = useState({
    labels: [],
    datasets: [],
  });
  const [optionsStockPriceCagr, setOptionsStockPriceCagr] = useState({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {},
    scales: {
      y: {},
    },
  });

  const [returnOnEquity, setReturnOnEquity] = useState(null);
  const [dataSetReturnOnEquity, setDataSetReturnOnEquity] = useState({
    labels: [],
    datasets: [],
  });
  const [optionsReturnOnEquity, setOptionsReturnOnEquity] = useState({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {},
    scales: {
      y: {},
    },
  });

  // for Peer Comparison
  const [tooltipOpenPeer, setTooltipOpenPeer] = useState({});
  const [fieldPeerComparison] = useState(fieldPeer);
  const [peerComparison, setPeerComparison] = useState([]);

  // for Quarter Result
  const [quarter, setQuarter] = useState([]);
  const [fieldQuarter, setFieldQuarter] = useState([]);
  const [objectQuarter, setObjectQuarter] = useState(fieldQuarterly);

  // for Profit Loss
  const [profitLoss, setProfitLoss] = useState([]);
  const [fieldProfitLoss, setFieldProfitLoss] = useState([]);
  const [objectProfitLoss, setObjectProfitLoss] = useState(fieldAnnualIncome);

  // for Balance Sheet
  const [balanceSheet, setBalanceSheet] = useState([]);
  const [fieldBalanceSheet, setFieldBalanceSheet] = useState([]);
  const [objectBalanceSheet, setObjectBalanceSheet] =
    useState(fieldBalanceSheets);

  // for chart
  const [coreChartCompanies, setCoreChartCompanies] = useState([]);
  const [coreChartCompareCompanies, setCoreChartCompareCompanies] = useState(
    []
  );
  const [companyIds, setCompanyIds] = useState([companyId]);
  const [seriesChart, setSeriesChart] = useState([]);
  const [categoriesChart, setCategoriesChart] = useState([]);
  const [yAxisChart, setYAxisChart] = useState([]);
  const [optionCompare, setOptionCompare] = useState(null);
  const [seriesTradingView, setSeriesTradingView] = useState([]);
  const [filterCategoryChart] = useState(fieldCategoryChart);
  const [filterCategoryChartSelected, setFilterCategoryChartSelected] =
    useState(fieldCategoryChart[0]);
  const [filterDateChartSelected, setFilterDateChartSelected] = useState({
    label: "1Yr",
    dateFrom: moment().subtract(1, "year").format("YYYY-MM-DD"),
    dateTo: moment().format("YYYY-MM-DD"),
  });
  const [filterDateChart] = useState([
    {
      label: "1m",
      ratio: 4,
      dateFrom: moment().subtract(1, "month").format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
    },
    {
      label: "6m",
      ratio: 24,
      dateFrom: moment().subtract(6, "month").format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
    },
    {
      label: "1Yr",
      ratio: 48,
      dateFrom: moment().subtract(1, "year").format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
    },
    {
      label: "3Yr",
      ratio: 100,
      dateFrom: moment().subtract(3, "year").format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
    },
    {
      label: "5Yr",
      ratio: 1000,
      dateFrom: moment().subtract(5, "year").format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
    },

    {
      label: "10Yr",
      ratio: 5000,
      dateFrom: moment().subtract(10, "year").format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
    },
    {
      label: "Max",
      ratio: 120,
      dateFrom: moment().subtract(50, "year").format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
    },
  ]);

  // for capital structure
  const [seriesCapitalStructure, setSeriesCapitalStructure] = useState([]);
  // for dividend summary
  const [seriesDividendSummary, setSeriesDividendSummary] = useState([]);
  const [dataDividendSummary, setDataDividendSummary] = useState(null);

  // for dividend history
  const [seriesDividendHistory, setSeriesDividendHistory] = useState([]);
  const [categoriesDividendHistory, setCategoriesDividendHistory] = useState(
    []
  );

  // for cash flow
  const [cashFlow, setCashFlow] = useState([]);
  const [fieldCashFlow, setFieldCashFlow] = useState([]);
  const [objectCashFlow, setObjectCashFlow] = useState(fieldCashFlows);

  // for ratio
  const [ratioData, setRatioData] = useState([]);
  const [fieldRatio, setFieldRatio] = useState([]);
  const [objectRatio] = useState(fieldRatios);

  // for shareholding pattern
  const [shareHoldingPattern, setShareHoldingPattern] = useState([]);
  const [dataSetShareHoldingPattern, setDataSetShareHoldingPattern] = useState({
    labels: [],
    datasets: [],
  });

  const [dataOptionsShareHoldingPattern, setDataOptionsShareHoldingPattern] =
    useState({
      responsive: true,
      interaction: {},
      plugins: {},
      scales: {},
    });

  // for announcement
  const [announcements, setAnnouncements] = useState([]);
  const [annualReports, setAnnualReports] = useState([]);

  // for parameters
  const { loading } = useQuery(QuerySummary, {
    variables: {
      code: companyId || "",
    },
    onCompleted: (data_) => {
      setCompaniesDetail(data_?.company[0]);
      onModifyCapitalStructure(data_?.company[0].stockSummaries[0]);
      onModifyAnnualReports(data_?.company[0].report);
    },

    onError: (error) => {
      console.log(error);
    },
  });

  // FETCH COMPANY DETAIL
  const [fetchCompanyDetail] = useLazyQuery(QueryDetail, {
    variables: {
      code: companyId || "",
    },
    onCompleted: (data_) => {
      onModifyDividentSummary(data_?.company[0].dividendSummary);
      onModidyDividendHistory(data_?.company[0].dividendHistory);
      setRatio(
        data_?.company[0].ratio.length > 0 ? data_?.company[0].ratio[0] : {}
      );
      setRatioData(data_?.company[0].ratio);
      onModifyCompound(data_?.company[0]);
      onModifyRatio(data_?.company[0].ratio);
    },

    onError: (error) => {
      console.log(error);
    },
  });

  // FETCH CHART

  const [fetchChart] = useLazyQuery(QueryDetail);

  // FETCH DOCUMENT
  const [fetchDocument] = useLazyQuery(QueryDetail);
  // FETCH QUARTER
  const [fetchQuarter] = useLazyQuery(QuarterResult, {
    variables: {
      code: companyId || "",
    },
    onCompleted: (data_) => {
      setPeerComparison(data_?.company[0]?.peerComparison);
      onModifyQuarter(data_?.company[0]?.quarterlyIncome);
      onModifyBalanceSheet(data_?.company[0]?.balanceSheet);
      onModifyCashFlow(data_?.company[0]?.cashFlow);
      onModifyAnnualIncome(data_?.company[0]?.annualIncome);
      onModifyShareHoldingPattern(data_?.company[0].stockHolders);
    },

    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    fetchCompanyDetail();
    fetchQuarter();
    onFetchChart(companyId);
    onFetchDocument();

    // eslint-disable-next-line
  }, []);

  // FETCH CHART
  const onFetchChart = async (companiesId) => {
    const result = await fetchChart({
      variables: {
        code: companiesId || "",
      },
    });

    if (!result?.result?.loading) {
      onModifyChart(
        result?.data?.company[0],
        filterCategoryChartSelected,
        filterDateChartSelected
      );
      updateCompanyName(result?.data?.company[0]);
      setCoreChartCompanies(result?.data?.company);
      setCoreChartCompareCompanies(result?.data?.company);
    }
  };

  const onFetchDocument = async (filter = "") => {
    const result = await fetchDocument({
      variables: {
        code: companyId || "",
        filterAnnouncement: filter,
      },
    });

    if (!result?.result?.loading) {
      setAnnouncements(result?.data?.company[0]?.announcement);
    }
  };

  const onFetchChartCompare = async (
    companiesId,
    coreChartCompareCompanies
  ) => {
    const result = await fetchChart({
      variables: { code: companiesId || "" },
    });

    if (!result?.result?.loading) {
      const newCoreChartCompareCompanies = coreChartCompareCompanies.filter(
        (item) => item.code !== result.data.company[0].code
      );
      const mergeCompanies = [
        ...newCoreChartCompareCompanies,
        result.data.company[0],
      ];
      setCoreChartCompareCompanies(mergeCompanies);
      onModifyCompareChart(
        mergeCompanies,
        filterCategoryChartSelected,
        filterDateChartSelected
      );
    }
  };

  const onSelectCategoryChart = async (data) => {
    await setIsCompareActive(false);
    await setCompanyIds([companyId]);
    await setCoreChartCompareCompanies(coreChartCompanies);
    await setFilterCategoryChartSelected(data);
    await onModifyChart(coreChartCompanies[0], data, filterDateChartSelected);
  };

  const onSelectDateChart = async (data) => {
    await setFilterDateChartSelected(data);
    if (isCompareActive) {
      await onModifyCompareChart(
        coreChartCompanies,
        filterCategoryChartSelected,
        data
      );
    } else {
      await onModifyChart(
        coreChartCompanies[0],
        filterCategoryChartSelected,
        data
      );
    }
  };

  const onSwitchChange = async () => {
    await setIsCompareActive(false);
    await setCompanyIds([companyId]);
    await setCoreChartCompanies(coreChartCompanies);
    await setFilterCategoryChartSelected(fieldCategoryChart[0]);
    await onModifyChart(
      coreChartCompanies[0],
      filterCategoryChartSelected,
      filterDateChartSelected
    );
    await setIsTradingView(!isTradingView);
  };

  const onCompareCheck = async (item) => {
    let isCompare = false;
    let newCompaniesId = [...companyIds];
    if (newCompaniesId.includes(item)) {
      newCompaniesId.splice(newCompaniesId.indexOf(item), 1);
    } else {
      newCompaniesId.push(item);
    }
    if (newCompaniesId?.length > 1) {
      isCompare = true;
    } else {
      isCompare = false;
    }
    await setIsCompareActive(isCompare);
    await setCompanyIds(newCompaniesId);
    await onFetchChartCompare(item, coreChartCompareCompanies);
  };

  const onModifyCompareChart = (
    data,
    filterCategorySelected,
    filterDateSelected
  ) => {
    const { dateFrom, dateTo } = filterDateSelected;

    let filteredData = null;

    switch (filterCategorySelected?.slug) {
      case "closingPrice":
        filteredData = data?.map((item) => {
          return {
            name: item?.name,
            data: {
              date: item?.stockHistories
                .filter((item) =>
                  moment(item.dateCreated).isBetween(
                    dateFrom,
                    dateTo,
                    null,
                    "[]"
                  )
                )
                ?.map((item) => item?.dateCreated),
              value: item?.stockHistories
                .filter((item) =>
                  moment(item.dateCreated).isBetween(
                    dateFrom,
                    dateTo,
                    null,
                    "[]"
                  )
                )
                ?.map((item) => item?.closingPrice),
            },
          };
        });

        break;
      case "stockPe":
        filteredData = data?.map((item) => {
          return {
            name: item?.name,
            data: {
              date: item?.ratioCharts
                .filter((item) =>
                  moment(item.dateCreated).isBetween(
                    dateFrom,
                    dateTo,
                    null,
                    "[]"
                  )
                )
                ?.map((item) => item?.dateCreated),
              value: item?.ratioCharts
                .filter((item) =>
                  moment(item.dateCreated).isBetween(
                    dateFrom,
                    dateTo,
                    null,
                    "[]"
                  )
                )
                ?.map((item) => item?.stockPe),
            },
          };
        });

        break;
      case "eps":
        filteredData = data?.map((item) => {
          return {
            name: item?.name,
            data: {
              date: item?.annualIncome
                .filter((item) =>
                  moment(item.dateCreated).isBetween(
                    dateFrom,
                    dateTo,
                    null,
                    "[]"
                  )
                )
                ?.map((item) => item?.dateCreated),
              value: item?.annualIncome
                .filter((item) =>
                  moment(item.dateCreated).isBetween(
                    dateFrom,
                    dateTo,
                    null,
                    "[]"
                  )
                )
                ?.map((item) => item?.eps),
            },
          };
        });

        break;
      case "dividendYield":
        filteredData = data?.map((item) => {
          return {
            name: item?.name,
            data: {
              date: item?.ratioCharts
                .filter((item) =>
                  moment(item.dateCreated).isBetween(
                    dateFrom,
                    dateTo,
                    null,
                    "[]"
                  )
                )
                ?.map((item) => item?.dateCreated),
              value: item?.ratioCharts
                .filter((item) =>
                  moment(item.dateCreated).isBetween(
                    dateFrom,
                    dateTo,
                    null,
                    "[]"
                  )
                )
                ?.map((item) => item?.dividendYield),
            },
          };
        });
        break;
      case "marketCapital":
        filteredData = data?.map((item) => {
          return {
            name: item?.name,
            data: {
              date: item?.ratioCharts
                .filter((item) =>
                  moment(item.dateCreated).isBetween(
                    dateFrom,
                    dateTo,
                    null,
                    "[]"
                  )
                )
                ?.map((item) => item?.dateCreated),
              value: item?.ratioCharts
                .filter((item) =>
                  moment(item.dateCreated).isBetween(
                    dateFrom,
                    dateTo,
                    null,
                    "[]"
                  )
                )
                ?.map((item) => item?.marketCapital),
            },
          };
        });
        break;
      default:
        break;
    }

    setOptionCompare({
      chart: {
        height: 400,
        type: "line",
        padding: 0,
        backgroundColor: state.darkMode ? "#212531" : "#fff",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: filteredData
          ? filteredData[0]?.data?.date?.map((item) =>
              moment(item).format("YYYY-MM-DD")
            )
          : [],

        tickInterval: filteredData
          ? Math.max(1, Math.ceil(filteredData[0]?.data?.date.length / 5))
          : 1,
        labels: {
          rotation: 0,
          style: {
            fontSize: "10px",
            color: state.darkMode ? "#fff" : "#000",
          },
        },
      },
      yAxis: {
        title: {
          text: filterCategorySelected?.label,
        },
        labels: {
          style: {
            color: state.darkMode ? "#fff" : "#000",
          },
        },
      },
      tooltip: {
        shared: true,
      },
      series: filteredData?.map((item) => {
        return {
          name: item?.name,
          data: item?.data?.value,
          tooltip: {
            valueSuffix: " IDR",
          },
        };
      }),

      credits: {
        enabled: false, // Hilangkan credit Highcharts
      },
      legend: {
        enabled: true,
        layout: "horizontal",
        align: "center",
        verticalAlign: "bottom",
        itemMarginBottom: 5,
        itemStyle: {
          color: state.darkMode ? "#fff" : "#000",
        },
      },
    });
  };

  const onModifyChart = (data, filterCategorySelected, filterDateSelected) => {
    const { dateFrom, dateTo } = filterDateSelected;
    let filteredData;
    const categorySlug = filterCategorySelected?.slug;
    const filterByDate = (item) =>
      moment(item.dateCreated).isBetween(dateFrom, dateTo, null, "[]");

    const actions = {
      price: () => {
        filteredData = data?.stockHistories?.filter(filterByDate);
        onModifyChartPrice(
          filteredData,
          filterCategorySelected,
          filterDateSelected
        );
        onModifyChartTrading(
          filteredData,
          filterCategorySelected,
          filterDateSelected
        );
      },
      pe_ratio: () => {
        filteredData = data?.ratioCharts?.filter(filterByDate);
        onModifyChartPE(filteredData, filterCategorySelected);
      },
      eps: () => {
        filteredData = data?.quarterlyIncome?.filter(filterByDate);
        onModifyChartEPS(filteredData, filterCategorySelected);
      },
      dividendYield: () => {
        filteredData = data?.ratioCharts?.filter(filterByDate);
        onModifyCharDividend(filteredData, filterCategorySelected);
      },
      marketCapitalization: () => {
        filteredData = data?.ratioCharts?.filter(filterByDate);
        onModifyMarketCap(filteredData, filterCategorySelected);
      },
    };

    if (actions[categorySlug]) {
      actions[categorySlug]();
    }
  };

  const onModifyChartTrading = (data) => {
    const newData = data
      ?.map((item) => [
        new Date(item.dateCreated).getTime(),
        item.openingPrice,
        item.highestPrice,
        item.lowestPrice,
        item.closingPrice,
      ])
      .sort((a, b) => a[0] - b[0]);

    setSeriesTradingView(newData);
  };
  const onModifyChartPrice = (data) => {
    const dates = data?.map((item) => item.dateCreated);

    const volumes = data?.map((item) => item.tradeVolume);
    const closingPrices = data?.map((item) => item.closingPrice);
    const ema50 = data?.map((item) => item.ema50);
    const ema100 = data?.map((item) => item.ema100);
    const ema200 = data?.map((item) => item.ema200);
    const sma50 = data?.map((item) => item.sma50);
    const sma100 = data?.map((item) => item.sma100);
    const sma200 = data?.map((item) => item.sma200);
    setCategoriesChart(
      dates?.map((item) => moment(item).format("DD MMM YYYY"))
    );

    setSeriesChart([
      {
        name: t("detail.volume"),
        type: "column",
        data: volumes,
        yAxis: 0,
        tooltip: {
          valueSuffix: " shares",
        },
      },
      {
        name: t("detail.price"),
        type: "line",
        data: closingPrices,
        tooltip: {
          valueSuffix: " IDR",
        },
        yAxis: 1,
      },
      {
        name: t("detail.EMA50"),
        type: "line",
        data: ema50,
        visible: false,
        yAxis: 1,
        tooltip: {
          valueSuffix: " IDR",
        },
      },
      {
        name: t("detail.EMA100"),
        type: "line",
        data: ema100,
        visible: false,
        yAxis: 1,
        tooltip: {
          valueSuffix: " IDR",
        },
      },
      {
        name: t("detail.EMA200"),
        type: "line",
        data: ema200,
        yAxis: 1,
        visible: false,
        tooltip: {
          valueSuffix: " IDR",
        },
      },
      {
        name: t("detail.SMA50"),
        type: "line",
        data: sma50,
        yAxis: 1,
        visible: false,
        tooltip: {
          valueSuffix: " IDR",
        },
      },
      {
        name: t("detail.SMA100"),
        type: "line",
        data: sma100,
        yAxis: 1,
        visible: false,
        tooltip: {
          valueSuffix: " IDR",
        },
      },
      {
        name: t("detail.SMA200"),
        type: "line",
        data: sma200,
        yAxis: 1,
        visible: false,
        tooltip: {
          valueSuffix: " IDR",
        },
      },
    ]);
    setYAxisChart([
      {
        title: {
          text: "Volume",
        },
      },
      {
        title: {
          text: "Price",
        },
        opposite: true,
      },
    ]);
  };
  const onModifyChartPE = (data, filterCategorySelected) => {
    const dates = data.map((item) => item.dateCreated);
    const stockPe = data.map((item) => item.stockPe);

    setCategoriesChart(
      dates?.map((date) => moment(date).format("DD MMM YYYY"))
    );
    setSeriesChart([
      {
        name: t("pe_ratio"),
        type: "line",
        data: stockPe,
        tooltip: {
          valueSuffix: " IDR",
        },
      },
    ]);

    setYAxisChart([
      {
        title: {
          text: t("pe_ratio"),
        },
      },
    ]);
  };
  const onModifyChartEPS = (data, filterCategoryChartSelected) => {
    const dates = data.map((item) => item.dateCreated);
    const eps = data.map((item) => item.epsLatestPeriod);

    setCategoriesChart(
      dates?.map((date) => moment(date).format("DD MMM YYYY"))
    );
    setSeriesChart([
      {
        name: t("eps"),
        type: "line",
        data: eps,
        tooltip: {
          valueSuffix: " IDR",
        },
      },
    ]);

    setYAxisChart([
      {
        title: {
          text: t("eps"),
        },
      },
    ]);
  };
  const onModifyCharDividend = (data, filterCategoryChartSelected) => {
    const dates = data?.map((item) => item?.dateCreated);
    const dividendYield = data?.map((item) => item?.dividendYield);

    setCategoriesChart(
      dates?.map((date) => moment(date).format("DD MMM YYYY"))
    );
    setSeriesChart([
      {
        name: t("dividendYield"),
        type: "line",
        data: dividendYield,
        tooltip: {
          valueSuffix: " IDR",
        },
      },
    ]);

    setYAxisChart([
      {
        title: {
          text: t("dividendYield"),
        },
      },
    ]);
  };
  const onModifyMarketCap = (data, filterCategoryChartSelected) => {
    const dates = data?.map((item) => item?.dateCreated);
    const marketCapital = data?.map((item) => item?.marketCapital);

    setCategoriesChart(
      dates?.map((date) => moment(date).format("DD MMM YYYY"))
    );
    setSeriesChart([
      {
        name: t("marketCapitalization"),
        type: "line",
        data: marketCapital,
        tooltip: {
          valueSuffix: " IDR",
        },
      },
    ]);

    setYAxisChart([
      {
        title: {
          text: t("marketCapitalization"),
        },
      },
    ]);
  };

  const onModifyDividentSummary = (data) => {
    setDataDividendSummary(data);
    setSeriesDividendSummary([
      {
        name: "Dividend Summary",
        colorByPoint: true,
        data: [
          {
            name: "Payout Ratio",
            y: data?.payoutRatioTtm,
            color: "#23B19B",
          },
          {
            name: "Earnings Retained",
            y: 100 - data?.payoutRatioTtm,
            color: "#E0E3EB",
          },
        ],
      },
    ]);
  };

  const onModidyDividendHistory = (data) => {
    const newData = [...data];
    const sortedData = newData.sort((a, b) => a?.yearBook - b?.yearBook);
    const years = sortedData.map((item) => item.yearBook);
    const dividendPerShare = sortedData.map((item) => item.dividendPerShare);
    const dividendYield = sortedData.map((item) => item.dividendYield);
    setCategoriesDividendHistory(years);
    setSeriesDividendHistory([
      {
        name: t("detail.dividend_yield"),
        type: "line",
        data: dividendYield,
        tooltip: {
          valueSuffix: "%",
        },
        color: "#FF9800",
        zIndex: 1,
      },
      {
        name: t("detail.diviend_per_share"),
        type: "column",
        data: dividendPerShare,
        tooltip: {
          valueSuffix: "",
        },
        color: "#42BCA8",
        yAxis: 1,
      },
    ]);
  };

  const onModifyCapitalStructure = (data) => {
    const newData = { ...data };

    setSeriesCapitalStructure([
      {
        data: [
          {
            name: "market_cap",
            y: newData?.marketCapitalization,
            color: "#23B19B",
          },
          {
            name: "debt",
            y: newData?.debt,
            color: "#FEA726",
          },
          {
            name: "minority_interest",
            y: newData?.minorityInterest,
            color: "#23B19B",
          },
          {
            name: "equivalents",
            y: newData?.cashEquivalents,
            color: "#FF3F80",
          },
          {
            name: "enterprise_value",
            y:
              newData?.marketCapitalization +
              newData?.debt +
              newData?.minorityInterest +
              newData?.cashEquivalents,
            color: "#3178F5",
            isSum: true,
          },
        ],

        pointPadding: 0,
      },
    ]);
  };

  const formatFileName = (fileName) => {
    const withoutExtension = fileName.replace(/\.[^/.]+$/, "");
    const withSpaces = withoutExtension.replace(/[-_]/g, " ");
    const codesPattern = companyId;
    const regex = new RegExp(`\\b(Tahunan|${codesPattern})\\b`, "gi");
    const cleanedString = withSpaces.replace(regex, "").trim();

    return cleanedString.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const onModifyAnnualReports = (data) => {
    let newData = [...data];

    let filterFinancial = newData
      ?.filter((item) => item?.fileName.includes("FinancialStatement"))
      .sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated))
      .map((item) => {
        return {
          dateCreated: item?.dateCreated,
          title: formatFileName(item?.fileName),
          fileName: item?.fileName,
          filePath: item?.filePath,
        };
      });
    setAnnualReports(filterFinancial);
  };

  const onModifyQuarter = (quarterlyIncome) => {
    // field dates
    const fields = [...quarterlyIncome]?.map((item) => {
      return item?.dateCreated;
    });
    fields.unshift("#");

    const fieldQuarter = fields.map((item) => {
      const month = new Date(item).getMonth() + 1; // getMonth() returns 0-indexed month
      let quarter;

      if (month >= 1 && month <= 3) {
        quarter = "Q1 " + moment(item).format("YY");
      } else if (month >= 4 && month <= 6) {
        quarter = "Q2 " + moment(item).format("YY");
      } else if (month >= 7 && month <= 9) {
        quarter = "Q3 " + moment(item).format("YY");
      } else if (month >= 10 && month <= 12) {
        quarter = "Q4 " + moment(item).format("YY");
      }

      return { dateCreated: item, quarter };
    });

    setFieldQuarter(fieldQuarter);

    // sort data quarter
    const data = [...quarterlyIncome];

    const dataQuarter = data.map((item) => {
      const month = new Date(item.dateCreated).getMonth() + 1; // getMonth() returns 0-indexed month
      let quarter;

      if (month >= 1 && month <= 3) {
        quarter = "Q1 " + moment(item.dateCreated).format("YY");
      } else if (month >= 4 && month <= 6) {
        quarter = "Q2 " + moment(item.dateCreated).format("YY");
      } else if (month >= 7 && month <= 9) {
        quarter = "Q3 " + moment(item.dateCreated).format("YY");
      } else if (month >= 10 && month <= 12) {
        quarter = "Q4 " + moment(item.dateCreated).format("YY");
      }

      return { ...item, quarter };
    });

    setQuarter(dataQuarter);
  };

  const onModifyAnnualIncome = (annualIncome) => {
    // field dates
    const fields = [...annualIncome]

      ?.filter((item_) => item_?.dateCreated !== null)
      .map((item) => {
        return item?.dateCreated;
      });
    fields.unshift("#");
    setFieldProfitLoss(fields);

    // sort data quarter
    const data = [...annualIncome]?.filter(
      (item_) => item_?.dateCreated !== null
    );
    setProfitLoss(data);
  };

  const onModifyBalanceSheet = (balanceSheet) => {
    // field dates
    const fields = [...balanceSheet]?.map((item) => {
      return item?.dateCreated;
    });
    fields.unshift("#");

    const fieldQuarter = fields.map((item) => {
      const month = new Date(item).getMonth() + 1; // getMonth() returns 0-indexed month
      let quarter;

      if (month >= 1 && month <= 3) {
        quarter = "Q1 " + moment(item).format("YY");
      } else if (month >= 4 && month <= 6) {
        quarter = "Q2 " + moment(item).format("YY");
      } else if (month >= 7 && month <= 9) {
        quarter = "Q3 " + moment(item).format("YY");
      } else if (month >= 10 && month <= 12) {
        quarter = "Q4 " + moment(item).format("YY");
      }

      return { dateCreated: item, quarter };
    });
    setFieldBalanceSheet(fieldQuarter);

    // sort data quarter
    const data = [...balanceSheet];

    const dataQuarter = data.map((item) => {
      const month = new Date(item.dateCreated).getMonth() + 1; // getMonth() returns 0-indexed month
      let quarter;

      if (month >= 1 && month <= 3) {
        quarter = "Q1 " + moment(item.dateCreated).format("YY");
      } else if (month >= 4 && month <= 6) {
        quarter = "Q2 " + moment(item.dateCreated).format("YY");
      } else if (month >= 7 && month <= 9) {
        quarter = "Q3 " + moment(item.dateCreated).format("YY");
      } else if (month >= 10 && month <= 12) {
        quarter = "Q4 " + moment(item.dateCreated).format("YY");
      }

      return { ...item, quarter };
    });

    setBalanceSheet(dataQuarter);
  };

  const onModifyCashFlow = (cashFlow) => {
    // field dates
    const fields = [...cashFlow]?.map((item) => {
      return item?.dateCreated;
    });
    fields.unshift("#");

    const fieldQuarter = fields.map((item) => {
      const month = new Date(item).getMonth() + 1; // getMonth() returns 0-indexed month
      let quarter;

      if (month >= 1 && month <= 3) {
        quarter = "Q1 " + moment(item).format("YY");
      } else if (month >= 4 && month <= 6) {
        quarter = "Q2 " + moment(item).format("YY");
      } else if (month >= 7 && month <= 9) {
        quarter = "Q3 " + moment(item).format("YY");
      } else if (month >= 10 && month <= 12) {
        quarter = "Q4 " + moment(item).format("YY");
      }

      return { dateCreated: item, quarter };
    });
    setFieldCashFlow(fieldQuarter);

    // sort data
    const data = [...cashFlow];
    const dataQuarter = data.map((item) => {
      const month = new Date(item.dateCreated).getMonth() + 1; // getMonth() returns 0-indexed month
      let quarter;

      if (month >= 1 && month <= 3) {
        quarter = "Q1 " + moment(item.dateCreated).format("YY");
      } else if (month >= 4 && month <= 6) {
        quarter = "Q2 " + moment(item.dateCreated).format("YY");
      } else if (month >= 7 && month <= 9) {
        quarter = "Q3 " + moment(item.dateCreated).format("YY");
      } else if (month >= 10 && month <= 12) {
        quarter = "Q4 " + moment(item.dateCreated).format("YY");
      }

      return { ...item, quarter };
    });
    setCashFlow(dataQuarter);
  };
  const onModifyRatio = (ratio) => {
    // field dates
    const fields = [...ratio]?.map((item) => {
      return item?.dateCreated;
    });
    fields.unshift("#");

    const fieldQuarter = fields.map((item) => {
      const month = new Date(item).getMonth() + 1; // getMonth() returns 0-indexed month
      let quarter;

      if (month >= 1 && month <= 3) {
        quarter = "Q1 " + moment(item).format("YY");
      } else if (month >= 4 && month <= 6) {
        quarter = "Q2 " + moment(item).format("YY");
      } else if (month >= 7 && month <= 9) {
        quarter = "Q3 " + moment(item).format("YY");
      } else if (month >= 10 && month <= 12) {
        quarter = "Q4 " + moment(item).format("YY");
      }

      return { dateCreated: item, quarter };
    });
    setFieldRatio(fieldQuarter);

    // sort data
    const data = [...ratio];

    const dataQuarter = data.map((item) => {
      const month = new Date(item.dateCreated).getMonth() + 1; // getMonth() returns 0-indexed month
      let quarter;

      if (month >= 1 && month <= 3) {
        quarter = "Q1 " + moment(item.dateCreated).format("YY");
      } else if (month >= 4 && month <= 6) {
        quarter = "Q2 " + moment(item.dateCreated).format("YY");
      } else if (month >= 7 && month <= 9) {
        quarter = "Q3 " + moment(item.dateCreated).format("YY");
      } else if (month >= 10 && month <= 12) {
        quarter = "Q4 " + moment(item.dateCreated).format("YY");
      }

      return { ...item, quarter };
    });
    setRatioData(dataQuarter);
  };

  const onModifyCompound = (compound) => {
    // SALES GROWTH
    const newCompound = { ...compound };
    setCompoundSalesGrowth(newCompound?.compoundSalesGrowth);
    setDataSetSalesGrowth({
      labels: newCompound?.compoundSalesGrowth?.map((item) => item.period),
      datasets: [
        {
          label: " Sales Growth (%)",
          data: newCompound?.compoundSalesGrowth?.map(
            (item) => item.percentage
          ),
          borderColor: "#4a90e2",
          backgroundColor: "rgba(74, 144, 226, 0.2)",
          pointBackgroundColor: "#4a90e2",
          fill: false,
          tension: 0.4,
          pointRadius: 6,
          pointHoverRadius: 8,
        },
      ],
    });
    setOptionsSalesGrowth({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
        datalabels: {
          display: true,
          align: "top",
          backgroundColor: "#E1EFFF",
          color: "#0078FF",
          font: {
            size: 12,
            weight: "bold",
          },
          formatter: (value) => `${value}%`,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
      layout: {
        padding: {
          left: 30,
          right: 30,
          top: 30,
          bottom: 30,
        },
      },
    });

    // PROFIT GROWTH
    setCompoundProfitGrowth(newCompound?.compoundProfitGrowth);
    setDataSetProfitGrowth({
      labels: newCompound?.compoundProfitGrowth?.map((item) => item.period),
      datasets: [
        {
          label: " Profit Growth (%)",
          data: newCompound?.compoundProfitGrowth?.map(
            (item) => item.percentage
          ),
          borderColor: "#4a90e2",
          backgroundColor: "rgba(74, 144, 226, 0.2)",
          pointBackgroundColor: "#4a90e2",
          fill: false,
          tension: 0.4,
          pointRadius: 6,
          pointHoverRadius: 8,
        },
      ],
    });
    setOptionsProfitGrowth({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
        datalabels: {
          display: true,
          align: "top",
          backgroundColor: "#E1EFFF",
          color: "#0078FF",
          font: {
            size: 12,
            weight: "bold",
          },
          formatter: (value) => `${value}%`,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
      layout: {
        padding: {
          left: 30,
          right: 30,
          top: 30,
          bottom: 30,
        },
      },
    });

    // PRICE
    setCompoundStockPriceCagr(newCompound?.stockPriceCagr);
    setDataSetStockPriceCagr({
      labels: newCompound?.stockPriceCagr?.map((item) => item.period),
      datasets: [
        {
          label: " Price CAGR",
          data: newCompound?.stockPriceCagr?.map((item) => item.percentage),
          borderColor: "#4a90e2",
          backgroundColor: "rgba(74, 144, 226, 0.2)",
          pointBackgroundColor: "#4a90e2",
          fill: false,
          tension: 0.4,
          pointRadius: 6,
          pointHoverRadius: 8,
        },
      ],
    });
    setOptionsStockPriceCagr({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
        datalabels: {
          display: true,
          align: "top",
          backgroundColor: "#E1EFFF",
          color: "#0078FF",
          font: {
            size: 12,
            weight: "bold",
          },
          formatter: (value) => `${value}%`,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
      layout: {
        padding: {
          left: 30,
          right: 30,
          top: 30,
          bottom: 30,
        },
      },
    });

    // ROE
    setReturnOnEquity(newCompound?.returnOnEquity);
    setDataSetReturnOnEquity({
      labels: newCompound?.returnOnEquity?.map((item) => item.period),
      datasets: [
        {
          label: " ROE (%)",
          data: newCompound?.returnOnEquity?.map((item) => item.percentage),
          borderColor: "#4a90e2",
          backgroundColor: "rgba(74, 144, 226, 0.2)",
          pointBackgroundColor: "#4a90e2",
          fill: false,
          tension: 0.4,
          pointRadius: 6,
          pointHoverRadius: 8,
        },
      ],
    });
    setOptionsReturnOnEquity({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
        datalabels: {
          display: true,
          align: "top",
          backgroundColor: "#E1EFFF",
          color: "#0078FF",
          font: {
            size: 12,
            weight: "bold",
          },
          formatter: (value) => `${value}%`,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
      layout: {
        padding: {
          left: 30,
          right: 30,
          top: 30,
          bottom: 30,
        },
      },
    });
  };

  const onModifyShareHoldingPattern = (data) => {
    const filteredData = data.filter((item) => item.percentage > 0);
    setShareHoldingPattern(filteredData);

    const labels = filteredData.map((item) => item.name);

    const percentages = filteredData.map((item) => item.percentage);
    setDataSetShareHoldingPattern({
      labels: labels,
      datasets: [
        {
          label: " Percentage",
          data: percentages,
          backgroundColor: labels.map((item) => isRandomColor()),
        },
      ],
    });

    setDataOptionsShareHoldingPattern({
      responsive: true,
      plugins: {
        legend: {
          position: "right",
          titleFont: {
            color: "white",
          },
          bodyFont: {
            color: "white",
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.label || "";
              if (label) {
                label += ": " + context.raw + "%";
              }
              return label;
            },
          },
          titleFont: {
            color: "white",
          },
          bodyFont: {
            color: "white",
          },
        },
        datalabels: {
          display: false,
        },
      },
    });
  };

  const isMinus = (value) => {
    if (value < 0) {
      return true;
    } else {
      return false;
    }
  };

  const toggleTooltip = (slug) => {
    setTooltipOpenPeer((prevState) => ({
      ...prevState,
      [slug]: !prevState[slug],
    }));
  };

  const onShowChild = (slug) =>
    setObjectQuarter(
      objectQuarter.map((item) =>
        item.childOf === slug
          ? { ...item, isShow: !item.isShow }
          : item.slug === slug
          ? { ...item, isBold: true, isOpen: !item.isOpen }
          : item
      )
    );

  const onShowChildPL = (slug) =>
    setObjectProfitLoss(
      objectProfitLoss.map((item) =>
        item.childOf === slug
          ? { ...item, isShow: !item.isShow }
          : item.slug === slug
          ? { ...item, isBold: true, isOpen: !item.isOpen }
          : item
      )
    );
  const onShowChildBalanceSheet = (slug) =>
    setObjectBalanceSheet(
      objectBalanceSheet.map((item) =>
        item.childOf === slug
          ? { ...item, isShow: !item.isShow }
          : item.slug === slug
          ? { ...item, isBold: true, isOpen: !item.isOpen }
          : item
      )
    );

  const onShowChildCashFlow = (slug) =>
    setObjectCashFlow(
      objectCashFlow.map((item) =>
        item.childOf === slug
          ? { ...item, isShow: !item.isShow }
          : item.slug === slug
          ? { ...item, isBold: !item.isBold, isOpen: !item.isOpen }
          : item
      )
    );

  return (
    <div className="detail">
      <MetaHelmet title="Stock Redbel and fundamental" description="Detail" />
      {/* Modal SIDE READ MORE */}
      <CModalSide isOpen={isOpenMore} setIsOpen={() => setIsOpenMore(false)}>
        <ModalHeader>
          <div className="d-flex flex-row gap-2">
            <span className="fw-bold font-large ">{companiesDetail?.name}</span>
            <div className="ms-2" onClick={() => setIsOpenMore(false)}>
              <IoCloseSharp size={20} />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            <p className="fw-semibold font-label">
              {t("detail.about")}{" "}
              <span className="font-small text-primary">{"[edit]"}</span>
            </p>
            <span
              className={`${
                state?.darkMode ? "text-white" : "text-secondary"
              } font-label`}
              dangerouslySetInnerHTML={{
                __html: companiesDetail?.about,
              }}
            />
          </div>
          <div>
            <p className="fw-semibold font-label">
              {t("detail.key_point")}{" "}
              <span className="font-small text-primary">{"[edit]"}</span>
            </p>
            <span
              className={`${
                state?.darkMode ? "text-white" : "text-secondary"
              } font-label`}
              dangerouslySetInnerHTML={{
                __html: companiesDetail?.keyPoints,
              }}
            />
          </div>
        </ModalBody>
      </CModalSide>

      {/* Modal Compre */}
      <Modal
        isOpen={isOpenCompre}
        toggle={() => setIsOpenCompre(false)}
        size="lg"
      >
        <ModalHeader toggle={() => setIsOpenCompre(false)} className="border-0">
          {t("detail.compare")}{" "}
          {i18n.language === "id"
            ? t(filterCategoryChartSelected?.slug)
            : filterCategoryChartSelected?.label}{" "}
          {t("with_others")}
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-row gap-4 flex-wrap">
            {peerComparison?.map((item, index) => {
              if (item?.code !== companyIds[0]) {
                return (
                  <label key={index}>
                    <input
                      type="checkbox"
                      checked={companyIds?.includes(item?.code) ? true : false}
                      onChange={() => onCompareCheck(item?.code)}
                    />{" "}
                    {item?.name}
                  </label>
                );
              } else {
                return null;
              }
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setIsOpenCompre(false)}>
            DONE
          </Button>
        </ModalFooter>
      </Modal>
      {loading && <CLoading />}
      {/* Section Summary */}
      <section id="summary">
        <SectionSummary
          companiesDetail={companiesDetail}
          darkMode={state?.darkMode}
          t={t}
          currentLang={i18n.language}
          isMinus={isMinus}
          isExpandedAbout={isExpandedAbout}
          setIsExpandedAbout={setIsExpandedAbout}
          setIsOpenMore={setIsOpenMore}
          isOpenMore={isOpenMore}
        />
      </section>
      <div>
        <Card className="mt-3">
          <CardBody>
            <div>
              <p className="font-title fw-semibold">
                {t("detail.capital_structure")}
              </p>
            </div>
            <Row className="d-flex align-items-center">
              <Col md={12} sm={12} lg={6}>
                <SectionChartCapitalStruktur series={seriesCapitalStructure} />
              </Col>
              <Col md={12} sm={12} lg={6}>
                <div className="d-flex flex-column ">
                  {seriesCapitalStructure?.length > 0 &&
                    seriesCapitalStructure[0]?.data?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            width: 300,
                            borderTop:
                              item?.name?.includes("Enterprise Value") &&
                              "1px solid rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <div className="d-flex flex-row justify-content-between">
                            <div className="col-sm-5 col-5">
                              <span className="font-description ">
                                {t("detail." + item?.name)}
                              </span>
                            </div>
                            <div>
                              <span className="font-description fw-bold">
                                {isCurrency(item?.y)}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col md={12} sm={12} lg={4}>
            <Card className="h-100">
              <CardBody>
                <div>
                  <p className="font-title fw-semibold">
                    {t("detail.divident_summary")}
                  </p>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div>
                    <SectionChartDivSummary
                      data={dataDividendSummary}
                      series={seriesDividendSummary}
                    />
                  </div>
                  <div style={{ width: "50%" }} className="ps-4">
                    <div className="d-flex flex-row justify-content-between">
                      <span className="font-description">
                        {t("detail.dividend_yield")} TTM
                      </span>
                      <span className="font-description fw-bold">
                        {dataDividendSummary?.dividendYieldTtm?.toFixed(2)}%
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                      <span className="font-description">
                        {t("detail.next_payment")}
                      </span>
                      <span className="font-description fw-bold">
                        {dataDividendSummary?.nextPayment || "-"}
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                      <span className="font-description">
                        {t("detail.next_ex_date")}
                      </span>
                      <span className="font-description fw-bold">
                        {dataDividendSummary?.nextExDate || "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={12} sm={12} lg={8}>
            <Card>
              <CardBody>
                <div>
                  <p className="font-title fw-semibold">
                    {t("detail.dividend_history")}
                  </p>
                </div>
                <SectionChartDivHistory
                  series={seriesDividendHistory}
                  categories={categoriesDividendHistory}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      {/* Section Chart */}
      <section id="chart">
        <Card className="mt-3">
          <CardBody>
            <div className="d-flex flex-lg-row-reverse flex-column justify-content-between">
              <div>
                <div className="py-2 rounded border px-2 shadow-sm">
                  <span className="font-label ">
                    <HiOutlinePresentationChartLine size={20} />{" "}
                    {t("detail.switch_to_tradingview")}
                  </span>
                  <label className="switch ms-2">
                    <input
                      type="checkbox"
                      checked={isTradingView}
                      onChange={onSwitchChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {!isTradingView && (
                <div>
                  <div className="d-flex flex-row gap-2">
                    <div className="horizontal-list-container">
                      <Nav className="horizontal-list gap-2" pills>
                        <NavItem
                          onClick={() => setIsOpenCompre(true)}
                          className={
                            isCompareActive
                              ? "filter-chart-active"
                              : "filter-chart"
                          }
                        >
                          <div>
                            <span>{t("detail.compare")} </span>{" "}
                            <span>
                              {i18n.language === "id"
                                ? t(filterCategoryChartSelected.slug)
                                : filterCategoryChartSelected?.label}
                            </span>
                          </div>
                        </NavItem>
                        {filterCategoryChart?.map((item) => (
                          <NavItem
                            onClick={() => onSelectCategoryChart(item)}
                            className={
                              filterCategoryChartSelected?.slug ===
                                item?.slug && !isCompareActive
                                ? "filter-chart-active"
                                : "filter-chart"
                            }
                          >
                            <div>
                              {i18n.language === "id"
                                ? t(item.slug)
                                : item?.label}
                            </div>
                          </NavItem>
                        ))}
                      </Nav>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {!isTradingView && (
              <div>
                {/* Tab Filter Date*/}
                <ButtonGroup className="my-2 border btn-sm btn-lg">
                  {filterDateChart?.map((item) => (
                    <Button
                      onClick={() => onSelectDateChart(item)}
                      className={
                        filterDateChartSelected?.label === item?.label
                          ? "text-primary border-0 shadow-none font-label"
                          : state?.darkMode
                          ? "text-white border-0 shadow-none font-label"
                          : "text-secondary border-0 shadow-none font-label"
                      }
                      style={{
                        backgroundColor:
                          filterDateChartSelected?.label === item?.label
                            ? state?.darkMode
                              ? "#2609b5"
                              : "#F3F4FF"
                            : state?.darkMode
                            ? "#606f7b"
                            : "#FFF",

                        fontWeight: "500",
                      }}
                    >
                      {item?.label}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
            )}
            {!isCompareActive && !isTradingView ? (
              <SectionChart
                t={t}
                seriesChart={seriesChart}
                categoriesChart={categoriesChart}
                yAxisChart={yAxisChart}
              />
            ) : isTradingView ? (
              <SectionChartTrading seriesTrading={seriesTradingView} />
            ) : (
              <SectionChartCompare t={t} optionCompare={optionCompare} />
            )}
          </CardBody>
        </Card>
      </section>
      {/* Section Analysis */}
      <section id="analysis">
        <SectionAnalys
          companiesDetail={companiesDetail}
          t={t}
          darkMode={state?.darkMode}
        />
      </section>
      {/* Section Compound */}
      <Row className="mt-4">
        <Col sm="6" md="6" lg="3">
          <Card>
            <CardBody>
              <div>
                <p className="font-title fw-bold">
                  {t("detail.SALESGROWTH")} %
                </p>
              </div>
              <div
                style={{
                  height: "150px",
                }}
              >
                <Line data={dataSetSalesGrowth} options={optionsSalesGrowth} />
              </div>
              {/* Custom labels below the chart */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "10px",
                }}
              >
                {compoundSalesGrowth?.map((item, index) => (
                  <div key={index} style={{ textAlign: "center" }}>
                    <div style={{ fontSize: "12px", color: "#9b9b9b" }}>
                      {item?.period}
                    </div>

                    <div className="fw-semibold font-description">
                      {item?.percentage}%
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="6" md="6" lg="3">
          <Card>
            <CardBody>
              <div>
                <p className="font-title fw-bold">
                  {t("detail.PROFITGROWTH")} %
                </p>
              </div>
              <div
                style={{
                  height: "150px",
                }}
              >
                <Line
                  data={dataSetProfitGrowth}
                  options={optionsProfitGrowth}
                />
              </div>
              {/* Custom labels below the chart */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "10px",
                }}
              >
                {compoundProfitGrowth?.map((item, index) => (
                  <div key={index} style={{ textAlign: "center" }}>
                    <div style={{ fontSize: "12px", color: "#9b9b9b" }}>
                      {item?.period}
                    </div>

                    <div className="fw-semibold font-description">
                      {item?.percentage}%
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="6" md="6" lg="3">
          <Card>
            <CardBody>
              <div>
                <p className="font-title fw-bold">{t("detail.PRICECAGR")} %</p>
              </div>
              <div
                style={{
                  height: "150px",
                }}
              >
                <Line
                  data={dataSetStockPriceCagr}
                  options={optionsStockPriceCagr}
                />
              </div>
              {/* Custom labels below the chart */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "10px",
                }}
              >
                {compoundstockPriceCagr?.map((item, index) => (
                  <div key={index} style={{ textAlign: "center" }}>
                    <div style={{ fontSize: "12px", color: "#9b9b9b" }}>
                      {item?.period}
                    </div>

                    <div className="fw-semibold font-description">
                      {item?.percentage}%
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="6" md="6" lg="3">
          <Card>
            <CardBody>
              <div>
                <p className="font-title fw-bold">ROE %</p>
              </div>
              <div
                style={{
                  height: "150px",
                }}
              >
                <Line
                  data={dataSetReturnOnEquity}
                  options={optionsReturnOnEquity}
                />
              </div>
              {/* Custom labels below the chart */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "10px",
                }}
              >
                {returnOnEquity?.map((item, index) => (
                  <div key={index} style={{ textAlign: "center" }}>
                    <div style={{ fontSize: "12px", color: "#9b9b9b" }}>
                      {item?.period}
                    </div>

                    <div className="fw-semibold font-description">
                      {item?.percentage}%
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* Section Peer comparison */}
      <section id="peers">
        <SectionPeer
          companiesDetail={companiesDetail}
          t={t}
          darkMode={state.darkMode}
          fieldPeerComparison={fieldPeerComparison}
          tooltipOpenPeer={tooltipOpenPeer}
          toggleTooltip={toggleTooltip}
          peerComparison={peerComparison}
        />
      </section>

      {/* Section QuarterResult */}
      <section id="quarters">
        <SectionQuarter
          t={t}
          darkMode={state.darkMode}
          quarter={quarter}
          fieldQuarter={fieldQuarter}
          objectQuarter={objectQuarter}
          onShowChild={onShowChild}
        />
      </section>
      {/* Section Profit Loss */}
      <section id="profitLoss">
        <SectionProfitLoss
          t={t}
          darkMode={state.darkMode}
          profitLoss={profitLoss}
          fieldProfitLoss={fieldProfitLoss}
          objectProfitLoss={objectProfitLoss}
          onShowChildPL={onShowChildPL}
        />
      </section>
      {/* Section Balance Sheet */}
      <section id="balanceSheet">
        <SectionBalance
          t={t}
          balanceSheet={balanceSheet}
          fieldBalanceSheet={fieldBalanceSheet}
          objectBalanceSheet={objectBalanceSheet}
          darkMode={state.darkMode}
          onShowChildBalanceSheet={onShowChildBalanceSheet}
        />
      </section>
      {/* Section Cash Flows */}
      <section id="cashFlow">
        <SectionCashFlow
          t={t}
          darkMode={state.darkMode}
          cashFlow={cashFlow}
          fieldCashFlow={fieldCashFlow}
          objectCashFlow={objectCashFlow}
          onShowChildCashFlow={onShowChildCashFlow}
        />
      </section>
      {/* Section Ratio */}
      <section id="ratios">
        <SectionRatio
          ratio={ratio}
          t={t}
          darkMode={state.darkMode}
          fieldRatio={fieldRatio}
          objectRatio={objectRatio}
          ratioData={ratioData}
        />
      </section>

      {/* Section Shareholding */}
      <section id="investors">
        <SectionShareholding
          dataSetShareHoldingPattern={dataSetShareHoldingPattern}
          dataOptionsShareHoldingPattern={dataOptionsShareHoldingPattern}
          t={t}
          darkMode={state.darkMode}
          shareHoldingPattern={shareHoldingPattern}
        />
      </section>
      <section>
        <SectionDocument
          announcements={announcements}
          onFetchDocument={(filter) => onFetchDocument(filter)}
          annualReports={annualReports}
        />
      </section>
    </div>
  );
};

export default Company;
