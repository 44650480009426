import { useMutation } from "@apollo/client";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useAlert } from "react-alert";
import { CiEdit } from "react-icons/ci";
import {
  FaArrowAltCircleDown,
  FaArrowAltCircleUp,
  FaDownload,
  FaPlus,
} from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { RiExternalLinkLine } from "react-icons/ri";
import { TiLink } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { Card, CardBody } from "reactstrap";
import CInput from "../../../components/CInput";
import { GlobalContext } from "../../../contexts/GlobalContext";
import UpdateProfile from "../../../graphQL/Mutation/UpdateProfile";
import { isCurrency } from "../../../utils/isCurrency";
import IsMobile from "../../../utils/IsMobile";
import userRef from "../fieldTable/userRef.json";

const SectionSummary = ({
  companiesDetail,
  darkMode,
  t,
  currentLang,
  isMinus,
  isExpandedAbout,
  setIsExpandedAbout,
  setIsOpenMore,
  isOpenMore,
}) => {
  const navigate = useNavigate();
  const mobileView = IsMobile();
  const [search, setSearch] = useState("");
  const alert = useAlert();
  const { parameters } = useContext(GlobalContext);
  const [tokenLogin] = useState(secureLocalStorage.getItem("token"));
  const [parametersTemp, setParametersTemp] = useState(parameters);
  const [dashboard_arr, setDashboardArr] = useState(
    secureLocalStorage.getItem("dashboard_arr")
      ? JSON.parse(secureLocalStorage.getItem("dashboard_arr"))
      : userRef
  );
  const [dashboard_obj] = useState(
    secureLocalStorage.getItem("dashboard_obj")
      ? JSON.parse(secureLocalStorage.getItem("dashboard_obj"))
      : null
  );

  const [updateRatio] = useMutation(UpdateProfile, {
    context: {
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      },
    },
    onCompleted: () => {
      secureLocalStorage.setItem(
        "dashboard_arr",
        JSON.stringify(dashboard_arr)
      );

      console.log("ratio updated");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const onAddSummary = (item) => {
    setSearch("");
    if (!tokenLogin) {
      alert.info(t("screen.pleas_login_first"));
      secureLocalStorage.setItem(
        "pageDirection",
        `/company/${companiesDetail?.code}`
      );
      navigate("/login");
    }
    const new_dashboard_array = [...dashboard_arr];

    // if (new_dashboard_array.length >= 15) {
    //   alert.info(t("screen.limit_reached"));
    //   return;
    // }
    if (!new_dashboard_array.some((summary) => summary.value === item.value)) {
      new_dashboard_array.push(item);
      setDashboardArr(new_dashboard_array);
      let newDashboard = {
        ...dashboard_obj,
      };
      newDashboard[item.section] = {
        ...newDashboard[item.section],
        [item.value]: true,
      };

      secureLocalStorage.setItem("dashboard_obj", JSON.stringify(newDashboard));

      updateRatio({
        variables: {
          dashboard: newDashboard,
        },
      });
    } else {
      alert.info(t("screen.parameter_exists"));
    }
  };

  const onSearch = (value) => {
    setSearch(value);
    const newParameters = parameters.filter((parameter) =>
      parameter.valueDisplayName.toLowerCase().includes(value.toLowerCase())
    );
    setParametersTemp(newParameters);
  };

  const onEditRatio = () => {
    if (!tokenLogin) {
      alert.info(t("screen.pleas_login_first"));
      secureLocalStorage.setItem(
        "pageDirection",
        `/manage-ratio/${companiesDetail?.code}`
      );
      navigate("/login");
    } else {
      navigate(`/manage-ratio/${companiesDetail?.code}`);
    }
  };

  return (
    <Card className="shadow-sm">
      <CardBody>
        <div className="d-flex flex-row-reverse align-items-center justify-content-between">
          {/* Button export and Follow */}
          <div className="col-sm-12 col-lg-4 col-md-12">
            <div className="d-flex flew-row align-items-center gap-3 justify-content-end">
              <button className="btn btn-outline-primary rounded text-primary">
                <FaDownload size={14} />{" "}
                {!mobileView && t("detail.export_excel")}
              </button>
              <button className="btn btn-primary rounded text-light">
                <FaPlus size={14} /> {t("detail.follow")}
              </button>
            </div>
          </div>
          {/* Summary Company */}
          <div className="col-sm-12 col-lg-8 col-md-12">
            <div className={`d-flex flew-row align-items-center gap-3`}>
              <div className="d-lg-block d-none">
                <span className="fw-bold font-extra-large">
                  {companiesDetail?.name}
                </span>
              </div>
              <div>
                <div className="d-flex flex-row gap-1 align-items-center">
                  <div>
                    <span className="font-label fw-semibold">
                      {moment().format("HH:mm") >= "16:00"
                        ? isCurrency(
                            companiesDetail?.stockSummaries?.length > 0
                              ? companiesDetail?.stockSummaries[0]?.closingPrice
                              : 0
                          )
                        : isCurrency(
                            companiesDetail?.stockSummaries?.length > 0
                              ? companiesDetail?.stockSummaries[0]?.price
                              : 0
                          )}
                    </span>
                  </div>
                  <div>
                    {isMinus(
                      companiesDetail?.stockSummaries?.length > 0
                        ? companiesDetail?.stockSummaries[0]?.change
                        : 0
                    ) ? (
                      <FaArrowAltCircleDown size={10} color="red" />
                    ) : (
                      <FaArrowAltCircleUp size={10} color="green" />
                    )}{" "}
                    <span
                      className={`font-description ${
                        isMinus(
                          companiesDetail?.stockSummaries?.length > 0
                            ? companiesDetail?.stockSummaries[0]?.change
                            : 0
                        )
                          ? "text-danger"
                          : "text-success"
                      }`}
                    >
                      {companiesDetail?.stockSummaries?.length > 0
                        ? companiesDetail?.stockSummaries[0]?.change
                        : 0}
                      {"%"}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "-5px",
                  }}
                >
                  <span className="text-secondary font-small py-0 my-0">
                    {companiesDetail?.stockSummaries?.length > 0
                      ? moment(
                          companiesDetail?.stockSummaries[0]?.dateCreated
                        ).format("DD MMM YYYY")
                      : ""}{" "}
                    {moment().format("HH:mm") >= "16:00"
                      ? `- ${t("detail.close_price")}`
                      : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row gap-5 align-items-center">
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    companiesDetail?.website?.includes("http")
                      ? companiesDetail?.website
                      : "https://" + companiesDetail?.website
                  }
                  className="font-label fw-semibold text-primary text-decoration-none"
                >
                  <TiLink size={16} />
                  {mobileView ? "website" : companiesDetail?.website}
                </a>
              </div>
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    companiesDetail?.website?.includes("http")
                      ? companiesDetail?.website
                      : "https://" + companiesDetail?.website
                  }
                  className="font-label fw-semibold text-secondary text-decoration-none"
                >
                  <RiExternalLinkLine size={16} />
                  {companiesDetail?.code}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-lg-row-reverse flex-column  justify-content-between mt-4 ">
          <div className="col-sm-12 col-lg-2 col-md-12 mt-sm-3 mt-lg-0 mt-3">
            <div className="mx-3 d-flex flex-column">
              <span className="fw-bold font-label">{t("detail.about")}</span>

              {companiesDetail?.about ? (
                <>
                  <div
                    style={{
                      maxHeight: isExpandedAbout ? "none" : "4.5em",
                    }}
                  >
                    <span
                      className={`font-description ${
                        !isExpandedAbout && "news-title"
                      } `}
                      dangerouslySetInnerHTML={{
                        __html: companiesDetail?.about,
                      }}
                    />
                  </div>
                  {!isExpandedAbout && companiesDetail?.about && (
                    <div
                      onClick={() => setIsExpandedAbout(!isExpandedAbout)}
                      className="text-center"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <IoIosArrowDown width={16} height={14} />
                    </div>
                  )}
                </>
              ) : (
                <span className="font-description">{t("detail.no_data")}</span>
              )}
            </div>
            <div className="mx-3 d-flex flex-column">
              <span className="fw-bold font-label">
                {t("detail.key_point")}
              </span>
              {companiesDetail?.keyPoints ? (
                <div
                  style={{
                    maxHeight: "3.0em",
                  }}
                >
                  <span
                    className="font-description news-title"
                    dangerouslySetInnerHTML={{
                      __html: companiesDetail?.keyPoints,
                    }}
                  />
                </div>
              ) : (
                <span className="font-description">{t("detail.no_data")}</span>
              )}
              {companiesDetail?.keyPoints && (
                <div
                  onClick={() => setIsOpenMore(!isOpenMore)}
                  className="text-center "
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <IoIosArrowDown width={16} height={14} />
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-lg-10 col-md-12 ">
            <div className="border-secondary border border info p-3 rounded w-100">
              <div className="d-flex flex-row  flex-wrap align-items-center">
                {dashboard_arr?.map((item, index) => (
                  <div
                    className={`col-sm-12 col-lg-4 col-12 col-md-12 ${
                      index % 2 === 0
                        ? darkMode
                          ? "bg-secondary"
                          : "bg-brown"
                        : ""
                    }`}
                  >
                    <div className="d-flex flex-row align-items-center justify-content-between gap-2 py-1 px-2">
                      <div
                        style={{
                          maxWidth: "65%",
                        }}
                      >
                        <span
                          className={`font-label ${
                            darkMode ? "text-light" : "text-dark"
                          }`}
                        >
                          {currentLang === "id"
                            ? t(item.value)
                            : item?.valueDisplayName}
                        </span>
                      </div>
                      <div>
                        <span className="font-label fw-semibold">
                          {item?.unit === "currency"
                            ? isCurrency(
                                companiesDetail?.stockSummaries?.length > 0
                                  ? companiesDetail?.stockSummaries[0]?.[
                                      item?.origin?.value
                                    ] || 0
                                  : 0
                              )
                            : item?.unit === "percentage"
                            ? companiesDetail?.stockSummaries?.length > 0
                              ? parseFloat(
                                  companiesDetail?.stockSummaries[0]?.[
                                    item?.origin?.value
                                  ] || 0.0
                                ).toFixed(2) + " %"
                              : 0 + " %"
                            : companiesDetail?.stockSummaries?.length > 0
                            ? item?.unit === "boolean"
                              ? companiesDetail?.stockSummaries[0]?.[
                                  item?.origin?.value
                                ]
                                ? "Yes"
                                : "No"
                              : companiesDetail?.stockSummaries[0]?.[
                                  item?.origin?.value
                                ]
                            : 0}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex  flex-lg-row flex-sm-column flex-column gap-3 mt-4 align-items-lg-center ">
                <div className="d-flex flex-column w-100">
                  <di className="d-flex flex-row justify-content-between">
                    <span className="font-label fw-bold">
                      {t("detail.add_ratio_table")}
                    </span>
                    {mobileView && (
                      <div
                        className="font-label gap-2 align-items-center text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={onEditRatio}
                      >
                        <CiEdit size={16} />
                        Edit Ratio
                      </div>
                    )}
                  </di>
                  <CInput
                    value={search}
                    widthInput={mobileView ? "100%" : "618px"}
                    heightInput={"40px"}
                    placeholder={t("detail.example_ratio") + ": sales"}
                    borderColor={"border-primary"}
                    onChange={(e) => {
                      onSearch(e.target.value);
                    }}
                  />
                </div>
                {!mobileView && (
                  <div className="w-50 mt-lg-3 ">
                    <div
                      className="font-label gap-2 align-items-center text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={onEditRatio}
                    >
                      <CiEdit size={16} />
                      Edit Ratio
                    </div>
                  </div>
                )}
              </div>
              {parametersTemp?.length > 0 && search?.length > 0 && (
                <div
                  className={`position-absolute p-2 border  ${
                    darkMode ? "bg-secondary text-light" : "bg-light"
                  } mt-1 rounded shadow-sm z-1`}
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    width: mobileView ? "250px" : "618px",
                  }}
                >
                  {parametersTemp
                    ?.filter((item) => item?.value?.origin?.key !== null)
                    .map(
                      (item, index) =>
                        item?.origin?.key && (
                          <div
                            onClick={() => onAddSummary(item)}
                            className=" p-2  card-search  "
                            style={{
                              fontSize: mobileView ? "10px" : "14px",
                              cursor: "pointer",
                            }}
                          >
                            {currentLang === "id" ? (
                              <>
                                <span className="me-3">{t(item.value)}</span>

                                <span className="fw-semibold">
                                  {"(" + item?.valueDisplayName + ")"}
                                </span>
                              </>
                            ) : (
                              item?.valueDisplayName
                            )}
                          </div>
                        )
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default SectionSummary;
