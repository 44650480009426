import { gql } from "@apollo/client";

const QueryRank = gql`
  query Rank($type: TYPE, $limit: Int) {
    rank(rankType: $type, limit: $limit) {
      name
      code
      stockSummaries {
        price
        change
        volume
        volumeRank
        stockRank
        gainerRank
        frequentRank
        tradeFrequency
        volume1WeekAverage
      }
    }
  }
`;

export default QueryRank;
