import { gql } from "@apollo/client";

const QuarterResult = gql`
  query QuarterResult($filter: String, $code: String) {
    company(filter: $filter, code: $code) {
      id
      code
      peerComparison(limit: 10) {
        id
        code
        name
        primarySector
        industry
        stockSummaries {
          price
          priceToEarning
          marketCapitalization
          dividendYield
          netProfitLatestQuarter
          yoyQuarterlyProfitGrowth
          salesLatestQuarter
          yoyQuarterlySalesGrowth
          returnOnCapitalEmployed
        }
      }
      annualIncome {
        dateCreated
        salesTotal
        salesGrowthPercentage
        expensesTotal
        materialCost
        manufacturingCost
        employeeCost
        otherCost
        operatingProfitTotal
        opmPercentage
        otherIncome
        interest
        depreciation
        profitBeforeTaxTotal
        taxPercentage
        netProfitTotal
        profitAfterTax
        profitFromAssociates
        reportedNetProfit
        minorityShare
        profitForEps
        exceptionalItemsAt
        profitForPe
        epsInRs
        devidentPayout
        profitBeforeTaxLatestPeriod
        totalRevenueLatestPeriod
        operatingIncomeLatestPeriod
        salesLatestPeriod
      }
      quarterlyIncome {
        profitBeforeTaxLatestPeriod
        totalRevenueLatestPeriod
        operatingIncomeLatestPeriod
        salesLatestPeriod
        dateCreated
        salesTotal
        salesGrowthPercentage
        expensesTotal
        employeeCost
        operatingProfitTotal
        opmPercentage
        otherIncomesTotal
        exceptionalItems
        otherIncomeNormal
        interest
        depreciation
        profitBeforeTaxTotal
        taxPercentage
        netProfitTotal
        profitFromAssociates
        exceptionalItemsAt
        minorityShare
        profitAfterTax
        reportedNetProfit
        profitForPe
        profitForEps
        epsInRs
        report {
          filePath
        }
      }
      balanceSheet {
        dateCreated
        equityCapital
        reserves
        borrowingsTotal
        longTermBorrowingsTotal
        shortTermBorrowingsTotal
        leaseLiabilities
        otherBorrowings
        otherLiabilitiesTotal
        tradePayable
        advanceFromCustomers
        otherLiabilityItems
        totalLiabilities
        fixedAssetsTotal
        land
        building
        plantMachinery
        equipments
        computers
        furnitureAndFittings
        vehicles
        intangibleAssets
        otherFixedAssets
        grossBlockTotal
        accumulatedDepreciation
        cwip
        investments
        otherFixedAssets
        inventories
        tradeReceivables
        receivablesOver
        receivablesUnder
        provForDoubtful
        cashEquivalents
        shortTermLoans
        totalAsset
      }
      cashFlow {
        dateCreated
        cashFromOperationLastYear
        profitFromOperationsTotal
        receivables
        inventory
        payable
        loanAdvances
        otherWcItems
        workingCapitalChangesTotal
        directTaxes
        cashFromInvestingLastYear
        fixedAssetsPurchased
        fixedAssetsSold
        investmentPurchased
        interestReceived
        otherInvestmentItems
        cashFromFinancingLastYear
        proceedFromBorrowings
        repaymentFromBorrowings
        interestPaidFin
        devidentPaid
        financialLiabilities
        otherFinancingItems
        netCashFlowsTotal
      }
      stockHolders {
        name
        category
        totalStock
        percentage
      }
    }
  }
`;

export default QuarterResult;
