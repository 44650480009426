import { gql } from "@apollo/client";

const ChangePassword = gql`
  mutation ChangePassword($oldPassword: String!, $password: String!) {
    changePassword(oldPassword: $oldPassword, password: $password) {
      message
      status
      error
    }
  }
`;

export default ChangePassword;
