import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { isCurrencyNumber } from "../../../utils/isCurrency";

const HollowCandlestickChart = ({ seriesTrading }) => {
  console.log("SERIES", seriesTrading);
  const { state } = useContext(GlobalContext);
  const { darkMode } = state;
  const [option, setOption] = useState({
    chart: {
      type: "candlestick",
      height: 600,
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "date",
    },
    yAxis: {
      title: {
        text: "Price",
      },
    },
    plotOptions: {
      candlestick: {
        color: "red", // Warna body jika close < open
        upColor: "green", // Warna body jika close > open
        lineColor: "black", // Warna garis outline
        upLineColor: "black", // Warna outline untuk hollow candlestick
      },
    },
    series: [],
  });

  useEffect(() => {
    setOption({
      ...option,
      chart: {
        ...option.chart,
        backgroundColor: darkMode ? "#212531" : "#fff",
      },
      xAxis: {
        ...option.xAxis,
        labels: {
          rotation: 0,
          style: {
            fontSize: "10px",
            color: darkMode ? "#ffffff" : "#000000",
          },

          formatter: function () {
            const date = new Date(this.value);
            return moment(date.toISOString().split("T")[0]).format("D MMM YY");
          },
        },
      },
      yAxis: {
        ...option.yAxis,
        labels: {
          style: {
            color: darkMode ? "#ffffff" : "#000000",
          },
        },
      },
      tooltip: {
        formatter: function () {
          const point = this.point;
          return `
                    <b>${moment(point.x).format("D MMM YY")}</b><br/>
                    Open: <b>${isCurrencyNumber(point.open)}</b><br/>
                    High: <b>${isCurrencyNumber(point.high)}</b><br/>
                    Low: <b>${isCurrencyNumber(point.low)}</b><br/>
                    Close: <b>${isCurrencyNumber(point.close)}</b><br/>
                `;
        },
        useHTML: true,
      },
      series: [
        {
          type: "candlestick",
          name: "Stock Price",
          data: seriesTrading,
        },
      ],
    });
    // eslint-disable-next-line
  }, [seriesTrading, darkMode]);

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={option}
      />
    </div>
  );
};

export default HollowCandlestickChart;
