import React, { useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const CPagination = ({ totalItems, itemsPerPage, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <PaginationItem
          key={i}
          active={i === currentPage}
          className="shadow-none"
        >
          <PaginationLink
            onClick={() => handlePageClick(i)}
            className="shadow-none"
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return pages;
  };

  return (
    <Pagination className="shadow-none pagination-container">
      {/* Previous button */}
      <PaginationItem disabled={currentPage === 1} className="shadow-none">
        <PaginationLink
          previous
          onClick={() => handlePageClick(currentPage - 1)}
        />
      </PaginationItem>

      {/* Page numbers */}
      {renderPageNumbers()}

      {/* Next button */}
      <PaginationItem
        disabled={currentPage === totalPages}
        className="shadow-none"
      >
        <PaginationLink next onClick={() => handlePageClick(currentPage + 1)} />
      </PaginationItem>
    </Pagination>
  );
};

export default CPagination;
