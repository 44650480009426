export function isCurrency(nominal) {
  nominal = nominal || 0;

  const formatNumber = (num) => {
    if (num >= 1e12) {
      return `${(num / 1e12).toFixed(2)} T`; // Trillion
    } else if (num >= 1e9) {
      return `${(num / 1e9).toFixed(2)} B`; // Billion
    } else if (num >= 1e6) {
      return `${(num / 1e6).toFixed(2)} M`; // Million
    } else if (num >= 1e3) {
      return `${(num / 1e3).toFixed(2)} K`; // Thousand
    }
    return num.toLocaleString("id-ID"); // Smaller numbers
  };

  const formattedValue = formatNumber(nominal);

  return <span>Rp {formattedValue}</span>;
}

export function isCurrencyWithOutRp(nominal) {
  nominal = nominal || 0;

  const formatNumber = (num) => {
    if (num >= 1e12) {
      return `${(num / 1e12).toFixed(2)} T`; // Trillion
    } else if (num >= 1e9) {
      return `${(num / 1e9).toFixed(2)} B`; // Billion
    } else if (num >= 1e6) {
      return `${(num / 1e6).toFixed(2)} M`; // Million
    } else if (num >= 1e3) {
      return `${(num / 1e3).toFixed(2)} K`; // Thousand
    }
    return num.toLocaleString("id-ID");
  };

  const price = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(nominal);

  let value = price.substring(0, price.length - 3).split("p")[1];

  return (
    <span>
      {price.includes("-") ? "-" : null}
      {formatNumber(Number(value.replace(/\./g, "")))}
    </span>
  );
}

export function isCurrencyNumber(nominal) {
  nominal = nominal ? nominal : 0;
  const price = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(nominal);
  let value = price.substring(0, price.length - 3).split("p")[1];
  return value;
}
