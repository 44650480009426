import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useContext, useEffect, useState } from "react";
import { NoData } from "../../../assets/img";
import CEmpty from "../../../components/CEmpty";
import { GlobalContext } from "../../../contexts/GlobalContext";

const SectionChartCompare = ({
  t,

  optionCompare,
}) => {
  const [optionChart, setOptionChart] = useState(optionCompare);
  const { state } = useContext(GlobalContext);

  useEffect(() => {
    setOptionChart(optionCompare);
  }, [optionCompare]);

  useEffect(() => {
    if (optionChart) {
      setOptionChart({
        ...optionChart,
        chart: {
          ...optionChart.chart,
          backgroundColor: state.darkMode ? "#212531" : "#fff",
        },
        xAxis: {
          ...optionChart.xAxis,
          labels: {
            ...optionChart.xAxis.labels,
            style: {
              ...optionChart.xAxis.labels.style,
              color: state.darkMode ? "#ffffff" : "#000000",
            },
          },
        },
        yAxis: {
          ...optionChart.yAxis,
          labels: {
            ...optionChart.yAxis.labels,
            style: {
              ...optionChart.yAxis.labels.style,
              color: state.darkMode ? "#ffffff" : "#000000",
            },
          },
        },
        legend: {
          ...optionChart.legend,
          itemStyle: {
            ...optionChart.legend.itemStyle,
            color: state.darkMode ? "#ffffff" : "#000000",
          },
        },
      });
    }
    // eslint-disable-next-line
  }, [state.darkMode]);

  return (
    <div>
      <div className="mt-3">
        {/* Chart */}

        {optionCompare ? (
          <HighchartsReact highcharts={Highcharts} options={optionChart} />
        ) : (
          <CEmpty
            className="d-flex justify-content-center align-items-center flex-column"
            image={<img src={NoData} alt="No Data" width={250} />}
            title={t("detail.no_data")}
            description={t("detail.no_data_filter")}
          />
        )}
      </div>
    </div>
  );
};

export default SectionChartCompare;
