import { gql } from "@apollo/client";

const QuerySummary = gql`
  query Summary($code: String) {
    company(code: $code) {
      id
      code
      name
      website
      about
      keyPoints
      reference
      primarySector
      industry
      stockSummaries {
        id
        createdAt
        updatedAt
        createdBy
        updatedBy
        stockRank
        volumeRank
        gainerRank
        frequentRank
        companyName
        companyCode
        stockName
        stockSummary
        boardCode
        remarks
        previousPrice
        highestPrice
        lowestPrice
        closingPrice
        change
        firstTrade
        tradeVolume
        tradeValue
        tradeFrequency
        individualIndex
        numberForeigner
        openingPrice
        bidPrice
        bidVolume
        offerPrice
        offerVolume
        foreignSell
        foreignBuy
        dateCreated
        listedShares
        tradebleShares
        weightForIndex
        price
        nonRegularVolume
        nonRegularValue
        nonRegularFrequency
        returnOver3Months
        returnOver6Months
        isSme
        isNotSme
        volume1MonthAverage
        volume1WeekAverage
        volume
        highPrice
        averagePrice
        lowPrice
        highPriceAllTime
        lowPriceAllTime
        returnOver1Day
        returnOver1Week
        returnOver1Month
        returnOver1Year
        returnOver3Year
        returnOver5Year
        dma50
        dma100
        dma200
        ema50
        ema100
        ema200
        sma50
        sma100
        sma200
        dma50PreviousDay
        dma100PreviousDay
        dma200PreviousDay
        ema50PreviousDay
        ema100PreviousDay
        ema200PreviousDay
        sma50PreviousDay
        sma100PreviousDay
        sma200PreviousDay
        rsi
        macd
        macdPreviousDay
        macdSignal
        macdSignalPreviousDay
        salesGrowth3Years
        salesGrowth5Years
        profitGrowth3Years
        profitGrowth5Years
        averageReturnOnEquity3Years
        averageReturnOnEquity5Years
        sales
        opm
        profitAfterTax
        returnOnCapitalEmployed
        eps
        changeInPromoterHolding
        salesLastYear
        operatingProfitLastYear
        otherIncomeLastYear
        ebitdtLastYear
        depreciationLastYear
        ebitLastYear
        interestLastYear
        profitBeforeTaxLastYear
        taxLastYear
        profitAfterTaxLastYear
        extraordinaryItemsLastYear
        netProfitLastYear
        dividendLastYear
        materialCostLastYear
        employeeCostLastYear
        opmLastYear
        npmLastYear
        operatingProfit
        interest
        depreciation
        epsLastYear
        ebit
        netProfit
        currentTax
        tax
        otherIncome
        lastAnnualResultDate
        salesLatestQuarter
        profitAfterTaxLatestQuarter
        yoyQuarterlySalesGrowth
        yoyQuarterlyProfitGrowth
        salesGrowth
        profitGrowth
        operatingProfitLatestQuarter
        otherIncomeLatestQuarter
        ebidtLatestQuarter
        depreciationLatestQuarter
        ebitLatestQuarter
        interestLatestQuarter
        profitBeforeTaxLatestQuarter
        taxLatestQuarter
        extraordinaryItemsLatestQuarter
        netProfitLatestQuarter
        gpmLatestQuarter
        opmLatestQuarter
        npmLatestQuarter
        equityCapitalLatestQuarter
        epsLatestQuarter
        operatingProfit2QuartersBack
        operatingProfit3QuartersBack
        sales2QuartersBack
        sales3QuartersBack
        netProfit2QuartersBack
        netProfit3QuartersBack
        operatingProfitGrowth
        lastResultDate
        debt
        equityCapital
        preferenceCapital
        reserves
        securedLoan
        unsecuredLoan
        balanceSheetTotal
        grossBlock
        revaluationReserve
        accumulatedDepreciation
        netBlock
        capitalWorkInProgress
        investments
        currentAssets
        currentLiabilities
        bookValueOfUnquotedInvestments
        marketValueOfQuotedInvestments
        contingentLiabilities
        totalAssets
        workingCapital
        leaseLiabilities
        inventory
        tradeReceivables
        faceValue
        cashEquivalents
        advanceFromCustomers
        tradePayables
        cashFromOperationsLastYear
        freeCashFlowLastYear
        cashFromInvestingLastYear
        cashFromFinancingLastYear
        netCashFlowLastYear
        cashBeginningOfLastYear
        cashEndOfLastYear
        marketCapitalization
        priceToEarning
        dividendYield
        priceToBookValue
        returnOnAssets
        debtToEquity
        returnOnEquity
        promoterHolding
        earningsYield
        pledgedPercentage
        industryPe
        enterpriseValue
        numberOfEquityShares
        bookValue
        inventoryTurnoverRatio
        quickRatio
        exportsPercentage
        piotroskiScore
        gFactor
        assetTurnoverRatio
        financialLeverage
        numberOfShareholders
        unpledgedPromoterHolding
        returnOnInvestedCapital
        debtorDays
        industryPbv
        creditRating
        workingCapitalDays
        earningPower
        grahamNumber
        cashConversionCycle
        daysPayableOutstanding
        daysReceivableOutstanding
        daysInventoryOutstanding
        publicHolding
        fiiHolding
        changeInFiiHolding
        diiHolding
        changeInDiiHolding
        priceToSales
        priceToFreeCashFlow
        evEbitda
        currentRatio
        interestCoverageRatio
        pegRatio
        workingCapitalToSalesRatio
        qoqProfits
        qoqSales
        netWorth
        marketCapToSales
        interestCoverage
        enterpriseValueToEbit
        debtCapacity
        debtToProfit
        totalCapitalEmployed
        croic
        debtplus
        leverage
        dividendPayout
        intrinsicValue
        cashDebtContingentLiabilitiesByMcap
        cashByMarketCap
        fiftyTwoWeekIndex
        downFromFiftyTwoWeekHigh
        upFromFiftyTwoWeekLow
        fromFiftyTwoWeekHigh
        mktCapToDebtCap
        dividendPayoutRatio
        graham
        priceToCashFlow
        roce3YrAvg
        pbXPe
        ncavps
        marketCapToCashFlow
        altmanZScore
        marketCapToQuarterlyProfit
        minorityInterest
      }
      report {
        dateCreated
        fileName
        filePath
      }
    }
  }
`;

export default QuerySummary;
