import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import CLoading from "../../components/CLoading";
import MetaHelmet from "../../components/MetaHelmet";
import QueryRank from "../../graphQL/Query/QueryRank";
import ChartMarket from "./chart/ChartMarket";

const Market = () => {
  const [stockRank, setStockRank] = useState([]);
  const [volumeRank, setVolumeRank] = useState([]);
  const [gainerRank, setGainerRank] = useState([]);
  const [frequentRank, setFrequentRank] = useState([]);
  const { t } = useTranslation("global");

  const [fetchData, { loading }] = useLazyQuery(QueryRank);

  useEffect(() => {
    onFetchStockRank();
    onFetchVolumeRank();
    onFetchGainerRank();
    onFetchFrequentRank();
    // eslint-disable-next-line
  }, []);

  const onFetchStockRank = async () => {
    const result = await fetchData({
      variables: {
        type: "STOCK_RANK",
        limit: 5,
      },
    });

    if (result?.data?.rank) {
      const data = result?.data?.rank
        ?.map((item) => ({
          code: item.code,
          name: item.name,
          change: item.stockSummaries[0]?.change,
          price: item.stockSummaries[0]?.price,
          volume: item.stockSummaries[0]?.volume,
          stockRank: item.stockSummaries[0]?.stockRank,
          volumeRank: item.stockSummaries[0]?.volumeRank,
          gainerRank: item.stockSummaries[0]?.gainerRank,
          frequentRank: item.stockSummaries[0]?.frequentRank,
          volume1WeekAverage: item.stockSummaries[0]?.volume1WeekAverage,
          tradeFrequency: item.stockSummaries[0]?.tradeFrequency,
        }))
        .sort((a, b) => b.price - a.price);
      setStockRank(data);
    }
  };
  const onFetchVolumeRank = async () => {
    const result = await fetchData({
      variables: {
        type: "VOLUME_RANK",
        limit: 5,
      },
    });
    if (result?.data?.rank) {
      const data = result?.data?.rank
        ?.map((item) => ({
          code: item.code,
          name: item.name,
          change: item.stockSummaries[0]?.change,
          price: item.stockSummaries[0]?.price,
          volume: item.stockSummaries[0]?.volume,
          stockRank: item.stockSummaries[0]?.stockRank,
          volumeRank: item.stockSummaries[0]?.volumeRank,
          gainerRank: item.stockSummaries[0]?.gainerRank,
          frequentRank: item.stockSummaries[0]?.frequentRank,
          volume1WeekAverage: item.stockSummaries[0]?.volume1WeekAverage,
          tradeFrequency: item.stockSummaries[0]?.tradeFrequency,
        }))
        .sort((a, b) => b.volume - a.volume);
      setVolumeRank(data);
    }
  };

  const onFetchGainerRank = async () => {
    const result = await fetchData({
      variables: {
        type: "GAINER_RANK",
        limit: 5,
      },
    });
    if (result?.data?.rank) {
      const data = result?.data?.rank
        ?.map((item) => ({
          code: item.code,
          name: item.name,
          change: item.stockSummaries[0]?.change,
          price: item.stockSummaries[0]?.price,
          volume: item.stockSummaries[0]?.volume,
          stockRank: item.stockSummaries[0]?.stockRank,
          volumeRank: item.stockSummaries[0]?.volumeRank,
          gainerRank: item.stockSummaries[0]?.gainerRank,
          frequentRank: item.stockSummaries[0]?.frequentRank,
          volume1WeekAverage: item.stockSummaries[0]?.volume1WeekAverage,
          tradeFrequency: item.stockSummaries[0]?.tradeFrequency,
        }))
        .sort((a, b) => b.change - a.change);
      setGainerRank(data);
    }
  };

  const onFetchFrequentRank = async () => {
    const result = await fetchData({
      variables: {
        type: "FREQUENT_RANK",
        limit: 5,
      },
    });
    if (result?.data?.rank) {
      const data = result?.data?.rank
        ?.map((item) => ({
          code: item.code,
          name: item.name,
          change: item.stockSummaries[0]?.change,
          price: item.stockSummaries[0]?.price,
          volume: item.stockSummaries[0]?.volume,
          stockRank: item.stockSummaries[0]?.stockRank,
          volumeRank: item.stockSummaries[0]?.volumeRank,
          gainerRank: item.stockSummaries[0]?.gainerRank,
          frequentRank: item.stockSummaries[0]?.frequentRank,
          volume1WeekAverage: item.stockSummaries[0]?.volume1WeekAverage,
          tradeFrequency: item.stockSummaries[0]?.tradeFrequency,
        }))
        .sort((a, b) => b.tradeFrequency - a.tradeFrequency);
      setFrequentRank(data);
    }
  };
  return (
    <div>
      <MetaHelmet
        title="Stock Market"
        description={
          " See top gainers, volume stockers, top frequent & top stock"
        }
      />
      {loading && <CLoading />}
      <div className="d-flex flex-column mb-2">
        <span className="font-extra-large fw-semibold">
          {t("market.title")}
        </span>
        <span className="font-label">{t("market.description")}</span>
      </div>
      <Row>
        <Col sm="6" lg="3" md="6" className="mt-2">
          <ChartMarket
            data={gainerRank}
            value="change"
            title={t("market.top_grainer")}
            name={t("market.change") + "%"}
            t={t}
          />
        </Col>
        <Col sm="6" lg="3" md="6" className="mt-2">
          <ChartMarket
            data={stockRank}
            value="price"
            title={t("market.stock_rank")}
            name={t("market.price")}
            t={t}
          />
        </Col>
        <Col sm="6" lg="3" md="6" className="mt-2">
          <ChartMarket
            data={volumeRank}
            value="volume"
            title={t("market.volume_stocker")}
            name={t("market.volume")}
            t={t}
          />
        </Col>

        <Col sm="6" lg="3" md="6" className="mt-2">
          <ChartMarket
            data={frequentRank}
            value="tradeFrequency"
            title={t("market.top_frequency")}
            name={t("market.frequent")}
            t={t}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Market;
