import { gql } from "@apollo/client";

const QueryDetail = gql`
  query Detail(
    $filter: String
    $code: String
    $dateFrom: Date
    $dateTo: Date
    $filterAnnouncement: String
  ) {
    company(filter: $filter, code: $code) {
      id
      code
      name
      website
      about
      keyPoints
      pros
      cons
      reference
      primarySector
      industry
      dividendSummary {
        payoutRatioTtm
        nextPayment
        nextExDate
        dividendYieldTtm
      }
      dividendHistory {
        id
        yearBook
        dividendPerShare
        dividendYield
      }
      stockSummaries {
        highestPrice
        lowestPrice
        price
        closingPrice
        change
        dateCreated
        tradeVolume
      }
      annualIncome(dateFrom: $dateFrom, dateTo: $dateTo) {
        dateCreated
        epsLatestPeriod
        returnOnCapitalEmployedLatestPeriod
      }
      balanceSheet {
        faceValue
      }
      ratioCharts(dateFrom: $dateFrom, dateTo: $dateTo) {
        dateCreated
        dividendYield
        bookValue
        marketCapital
        stockPe
        returnOnEquity
        debtorDays
        inventoryDays
        dividendYield
        daysPayable
        cashConversionCycle
        workingCapitalDays
        rocePercentage
      }
      quarterlyIncome {
        dateCreated
        epsLatestPeriod
      }
      ratio(dateFrom: $dateFrom, dateTo: $dateTo) {
        dateCreated
        dividendYield
        bookValue
        marketCapital
        stockPe
        returnOnEquity
        debtorDays
        inventoryDays
        dividendYield
        daysPayable
        cashConversionCycle
        workingCapitalDays
        rocePercentage
        priceToEarning
        priceToCash
      }
      stockHistories(dateFrom: $dateFrom, dateTo: $dateTo) {
        dateCreated
        tradeVolume
        ema50
        ema100
        ema200
        sma50
        sma100
        sma200
        highestPrice
        lowestPrice
        openingPrice
        closingPrice
      }
      compoundSalesGrowth {
        percentage
        period
      }
      compoundProfitGrowth {
        percentage
        period
      }
      stockPriceCagr {
        percentage
        period
      }
      returnOnEquity {
        percentage
        period
      }
      announcement(filter: $filterAnnouncement) {
        id
        title
        subtitle
        announcementDate
        attachments {
          id
          fileUrl
          pdfFileName
          isAttachment
        }
      }
    }
  }
`;

export default QueryDetail;
