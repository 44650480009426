import { gql } from "@apollo/client";

const QueryProfile = gql`
  query Profile {
    profile {
      id
      email
      username
      firstName
      lastName
      userProfiles {
        dob
        imageUrl
        gender
        screen
        dashboard {
          section
          sectionDisplayName
          value
          valueDisplayName
          unit
          origin {
            key
            value
          }
        }
      }
    }
  }
`;

export default QueryProfile;
