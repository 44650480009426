import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import { IoIosArrowDropright } from "react-icons/io";
import { Card, CardBody } from "reactstrap";
import { isCurrencyNumber } from "../../../utils/isCurrency";

const ChartMarket = ({ data, value, name, title, t }) => {
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: 200,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [],
      title: {
        text: null,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
        align: "high",
      },
      labels: {
        overflow: "justify",
      },
    },
    tooltip: {
      useHTML: true,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
      series: {
        pointWidth: 15,
      },
    },
    series: [
      {
        name: "",
        data: [],
      },
    ],
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
  });

  useEffect(() => {
    setOptions({
      ...options,
      xAxis: {
        ...options.xAxis,
        categories: data.map((item) => item.code),
      },
      series: [
        {
          ...options.series[0],
          data: data.map((item) => item[value]),
        },
      ],
      tooltip: {
        ...options.tooltip,
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        formatter: function () {
          const stock = data.find((item) => item?.code === this.key);
          return `
          <b>${stock?.name}</b><br/>
         ${t("market.current_price")}: Rp${isCurrencyNumber(stock?.price)}<br/>
         ${t("market.day_change")}: ${stock?.change}%<br/>
         ${t("market.day_volume")}: ${stock?.volume}<br/>
         ${t("market.week_volume_avg")}: ${stock?.stockRank}<br/>
        ${t("market.frequent")}: ${stock?.tradeFrequency}<br/>
      
        `;
        },
      },
    });
    // eslint-disable-next-line
  }, [data]);
  return (
    <Card>
      <div className="d-flex justify-content-between flex-row mt-3">
        <div className="d-flex justify-content-start">
          <div className="bg-brown p-1 ">
            <span className="text-dark fw-bold font-description">{title}</span>
          </div>
        </div>
        <div className="d-flex flex-row gap-1 align-items-center me-1">
          <span className="font-small text-primary">
            {t("market.view_all")}
          </span>
          <IoIosArrowDropright size={16} className="text-primary" />
        </div>
      </div>
      <CardBody>
        <HighchartsReact highcharts={Highcharts} options={options} />
        <div className="d-flex justify-content-center">
          <span className="font-label ">{name}</span>
        </div>
      </CardBody>
    </Card>
  );
};

export default ChartMarket;
