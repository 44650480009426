import { gql } from "@apollo/client";

const QueryCompareSector = gql`
  query CompareSector($sector: String, $page: Int, $limit: Int) {
    companyPagination(sector: $sector, page: $page, limit: $limit) {
      page
      limit
      count
      data {
        ... on CompanySchema {
          id
          code
          name
          primarySector
          industry
          stockSummaries {
            price
            priceToEarning
            marketCapitalization
            dividendYield
            netProfitLatestQuarter
            yoyQuarterlyProfitGrowth
            salesLatestQuarter
            yoyQuarterlySalesGrowth
            returnOnCapitalEmployed
          }
        }
      }
    }
  }
`;

export default QueryCompareSector;
