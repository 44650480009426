import { useLazyQuery } from "@apollo/client";
import React, { createContext, useEffect, useState } from "react";
import QueryParameters from "../graphQL/Query/QueryParameters";
import QuerySettingApplication from "../graphQL/Query/QuerySettingApplication";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [state, setState] = useState({
    darkMode: localStorage.getItem("darkMode")
      ? JSON.parse(localStorage.getItem("darkMode"))
      : window.matchMedia("(prefers-color-scheme: dark)").matches
      ? true
      : false,
    sectionActive: "summary",
    company: null,
  });
  const [parameters, setParameters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState(null);

  // parameters on context
  const [fetchParameters] = useLazyQuery(QueryParameters, {
    onCompleted: (data) => {
      setParameters(data.parameters);
    },
  });

  const [fetchSettings] = useLazyQuery(QuerySettingApplication, {
    onCompleted: (data) => {
      setSettings(data.settings);
    },
  });

  useEffect(() => {
    fetchParameters();
    fetchSettings();
    // eslint-disable-next-line
  }, []);

  // get update section active
  const updateSection = (section) =>
    setState((prevState) => ({ ...prevState, sectionActive: section }));

  const toggleTheme = () => {
    localStorage.setItem("darkMode", JSON.stringify(!state.darkMode));
    setState((prevState) => ({
      ...prevState,
      darkMode: !prevState.darkMode,
    }));
  };

  const updateCompanyName = (value) =>
    setState((prevState) => ({ ...prevState, company: value }));

  return (
    <GlobalContext.Provider
      value={{
        state,
        parameters,
        settings,
        loading,
        setLoading,
        updateSection,
        toggleTheme,
        updateCompanyName,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
