import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useContext, useEffect, useState } from "react";
import { NoData } from "../../../assets/img";
import CEmpty from "../../../components/CEmpty";
import { GlobalContext } from "../../../contexts/GlobalContext";

const SectionChart = ({ t, seriesChart, categoriesChart, yAxisChart }) => {
  const { state } = useContext(GlobalContext);
  const { darkMode } = state;

  const [optionChart, setOptionChart] = useState({
    title: {
      text: "",
    },
    xAxis: {
      categories: [],
      tickInterval: 1,
      labels: {
        rotation: 0,
        style: {
          fontSize: "10px",
        },
      },
    },
    credits: {
      enabled: false,
    },

    tooltip: {
      shared: true,
    },
    series: [],
    legend: {
      enabled: true,
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      itemStyle: {
        color: darkMode ? "#ffffff" : "#000000",
      },
    },
  });

  useEffect(() => {
    const tickInterval = Math.max(1, Math.ceil(categoriesChart?.length / 5));
    setOptionChart({
      ...optionChart,
      chart: {
        ...optionChart.chart,
        backgroundColor: darkMode ? "#212531" : "#ffffff",
      },
      xAxis: {
        ...optionChart.xAxis,
        tickInterval: tickInterval,
        categories: categoriesChart,
        labels: {
          ...optionChart.xAxis.labels,
          style: {
            ...optionChart.xAxis.labels.style,
            color: darkMode ? "#ffffff" : "#000000",
          },
        },
      },
      yAxis:
        yAxisChart?.length > 0
          ? yAxisChart?.map((item) => {
              return {
                ...item,
                labels: {
                  ...item.labels,
                  style: {
                    color: darkMode ? "#ffffff" : "#000000",
                  },
                },
              };
            })
          : yAxisChart,
      series: seriesChart,
      legend: {
        ...optionChart.legend,
        backgroundColor: darkMode ? "#212531" : "#ffffff",
        itemStyle: {
          ...optionChart.legend.itemStyle,
          color: darkMode ? "#ffffff" : "#000000",
        },
      },
    });
    // eslint-disable-next-line
  }, [seriesChart, categoriesChart, darkMode]);

  return (
    <div>
      <div className="mt-3">
        {/* Chart */}

        {seriesChart?.length ? (
          <HighchartsReact highcharts={Highcharts} options={optionChart} />
        ) : (
          <CEmpty
            className="d-flex justify-content-center align-items-center flex-column"
            image={<img src={NoData} alt="No Data" width={250} />}
            title={t("detail.no_data")}
            description={t("detail.no_data_filter")}
          />
        )}
      </div>
    </div>
  );
};

export default SectionChart;
